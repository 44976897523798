<router-outlet>
</router-outlet>

<!-- Cookies Banner  -->
<div *ngIf="cookieBannerVisible && !hasRoute('maintenance')" class="cookies-banner position-fixed fixed-bottom">
    <div class="container">
        <div class="row justify-content-between align-items-center py-2">
            <div class="col-sm-8 col-12 small">
                <span class="text-light mt-1">This website uses cookies to provide you with a great user experience. <a (click)="navbarCollapsed = true; privacyDataCollection()" target="_blank">Read our privacy policy here</a>.</span>
            </div>
            <div class="col-sm-4 col-12 justify-content-center align-items-center text-white cookies-actions">
                <button class="btn btn-sm btn-success mr-2" type="button" (click)="allowCookies()">Agree</button>
                <button class="btn btn-sm btn-outline-light" type="button" (click)="cookieBannerVisible = false">Dismiss</button>
            </div>
        </div>
    </div>
</div>