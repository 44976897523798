import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapsModule } from './maps/maps.module';

import { ZsaService } from './services/zsa.service';
import { GlossaryService } from './services/glossary.service';

import { Ng2HighchartsModule } from 'ng2-highcharts';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from './pipes/safe-pipe.module';
import { PrivatePolicyComponent } from './private-policy/private-policy.component';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartComponent } from './dashboard/chart/chart.component';
import { OrgSelectComponent } from './dashboard/org-select/org-select.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PrivatePolicyComponent,
    DashboardComponent,
    ChartComponent,
    OrgSelectComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    Ng2HighchartsModule,
    NgxMapboxGLModule.withConfig({ accessToken: 'pk.eyJ1IjoiZGh1Z2hlc2JtYyIsImEiOiJjazQ5bXAxYW8wNmM2M2ZwY3N2aTR4emU3In0.7rhqvNQKGZ_ciuuxVAdV3A' }),
    NgbModule,
    NgPipesModule,
    SafePipeModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    ZsaService,
    GoogleAnalyticsService,
    GlossaryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
