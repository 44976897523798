import { Component, Input, OnChanges } from '@angular/core';
import { Org, Tier } from '../constants';
import { ZsaService } from 'src/app/services/zsa.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {

  @Input() public chart: Tier;
  @Input() public org: Org;
  @Input() private colourBlind: boolean;

  public orgData: any;
  public chartOptions: any;
  public error: boolean;

  private colours: string[];

  constructor(
    private zsaService: ZsaService
  ) { }

  ngOnChanges(): void {
    this.chartOptions = null;
    this.error = false;
    this.getChartDetails(this.chart.chartId);
    // TODO: Only retrieve chart details and data if not already stored
  }

  private getChartDetails(chartId: number): void {
    this.zsaService.ChartDetail(chartId).subscribe(
      success => {
        let chartDetails = success.data.chartDetail;
        this.chart.chartDetails = chartDetails ? chartDetails : null;
        this.chart.chartLatestDate = chartDetails ? chartDetails.dates[0] : null;
        this.colours = this.setColours(chartDetails);
        this.getChartData(chartId, this.chart.chartLatestDate.dateId, this.chart.orgTypeId || null);
      },
      error => {
        console.log(error);
      }
    )
  }

  private setColours(chartDetails: any): any {
    if (this.colourBlind && chartDetails.blindAlternative == 'Y') {
      return chartDetails.colours.map(colour => colour.blindColour);
    } else {
      return chartDetails.colours.map(colour => colour.colour);
    }
  }

  private getChartData(chartId: number, dateId: number, orgTypeId?: number): void {
    this.zsaService.ChartData(chartId, dateId, orgTypeId).subscribe(
      success => {
        let chartData = success.data.chartData;
        this.chart.chartData = chartData ? chartData : null;
        this.orgData = chartData.find(data => data.code === this.org.code);
        if (chartData && chartData.length) {
          this.chart.chartAverages = this.setAverages(chartData);
          this.createChart(this.chart);
        } else {
          this.error = true;
        }
      },
      error => {
        this.error = true;
      }
    )
  }

  // TODO: Move to shared component for /maps
  // TODO: Needs model
  private setAverages(chartData: any): any {
    let 
      unsortedData = chartData.map((a) => a.result),
      sortedData = unsortedData.sort((a, b) => { return a - b }),
      half = Math.floor(sortedData.length / 2),
      median,
      count = sortedData.length,
      average = sortedData.reduce((a, b) => a + b, 0) / count,
      per20 = Math.ceil(count * 0.2) - 1,
      per40 = Math.ceil(count * 0.4) - 1,
      per60 = Math.ceil(count * 0.6) - 1,
      per80 = Math.ceil(count * 0.8) - 1,
      min = sortedData.reduce((a, b) => Math.min(a, b)),
      max = sortedData.reduce((a, b) => Math.max(a, b));

    if (sortedData.length % 2) {
      median = sortedData[half];
    } else {
      median = (sortedData[half - 1] + sortedData[half]) / 2.0;
    }

    return {
      min: min,
      max: max,
      mean: average,
      median: median,
      count: count,
      quintile_20: sortedData[per20],
      quintile_40: sortedData[per40],
      quintile_60: sortedData[per60],
      quintile_80: sortedData[per80]
    };
  }

  // TODO: Move base chart to a constant
  
  private createChart(chart: Tier): void {
    this.chartOptions = {
      title: { text: '' },
      chart: {
        type: 'bar',
        animation: false,
        style: { fontFamily: 'Helvetica, Arial, sans-serif' },
        backgroundColor: 'transparent',
        height: '75',
        spacing: [0,0,0,0]
      },
      exporting: {
        enabled: false
      },
      colors: ['#48587F'],
      legend: false,
      credits: false,
      xAxis: {
        visible: false
      },
      yAxis: {
        title: '',
        endOnTick: false,
        min: chart.chartAverages.min,
        max: chart.chartAverages.max
      },
      plotOptions: {
        columnrange: {
          grouping: false
        },
        bar: {
          borderWidth: 0
        },
        series: {
          animation: false,
          marker: {
            lineWidth: 1,
            lineColor: 'white'
          }
        },
      },
      series: [
        {
          name: 'Q80',
          data: [ [chart.chartAverages.quintile_80, chart.chartAverages.max] ],
          color: this.colours[0],
          type: "columnrange",
          tooltip: {
            headerFormat: null,
            pointFormat: '<span style="font-weight: bold">{series.name}</span>: <b>{point.low:.1f}-{point.high:.1f}</b><br/>'
          }
        },
        {
          name: 'Q60',
          data: [ [chart.chartAverages.quintile_60, chart.chartAverages.quintile_80] ],
          color: this.colours[1],
          type: "columnrange",
          tooltip: {
            headerFormat: null,
            pointFormat: '<span style="font-weight: bold">{series.name}</span>: <b>{point.low:.1f}-{point.high:.1f}</b><br/>'
          }
        },
        {
          name: 'Median',
          data: [ [chart.chartAverages.quintile_40, chart.chartAverages.quintile_60] ],
          color: this.colours[2],
          type: "columnrange",
          tooltip: {
            headerFormat: null,
            pointFormat: '<span style="font-weight: bold">{series.name}</span>: <b>{point.low:.1f}-{point.high:.1f}</b><br/>'
          }
        },
        {
          name: 'Q40',
          data: [ [chart.chartAverages.quintile_20, chart.chartAverages.quintile_40] ],
          color: this.colours[3],
          type: "columnrange",
          tooltip: {
            headerFormat: null,
            pointFormat: '<span style="font-weight: bold">{series.name}</span>: <b>{point.low:.1f}-{point.high:.1f}</b><br/>'
          }
        },
        {
          name: 'Q20',
          data: [ [chart.chartAverages.min, chart.chartAverages.quintile_20] ],
          color: this.colours[4],
          type: "columnrange",
          tooltip: {
            headerFormat: null,
            pointFormat: '<span style="font-weight: bold">{series.name}</span>: <b>{point.low:.1f}-{point.high:.1f}</b><br/>'
          }
        },
        {
          id: 'national',
          name: 'National median',
          type: 'spline',
          color: 'black',
          lineWidth: 5,
          lineColor: 'red',
          marker: { radius: 7 },
          data: [ chart.chartAverages.median ],
          tooltip: {
            headerFormat: null,
            pointFormat: '<span style="font-weight: bold; color: {series.color}">{point.y:.1f}</span>'
          }
        },
        {
          id: 'organisation',
          name: 'Organisation value',
          type: 'scatter',
          color: 'green',
          lineWidth: 5,
          marker: { radius: 7 },
          visible: this.orgData ? true : false,
          data: [ this.orgData?.result ],
          tooltip: {
            headerFormat: null,
            pointFormat: '<span style="font-weight: bold; color: {series.color}">{point.y:.1f}</span>'
          }
        },
      ],
    };
  }
}