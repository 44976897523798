import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";

import { ZsaService } from '../services/zsa.service';

import { Map } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';

import { Org } from '../dashboard/constants';

// Import pipes
import { UniquePipe, FlattenPipe, OrderByPipe } from 'ngx-pipes';
import { MetatagsService } from '../services/metatags.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { GlossaryService } from '../services/glossary.service';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  providers: [UniquePipe, FlattenPipe, OrderByPipe],
  
})
export class MapsComponent implements OnInit {
  subscriptions = [];
 
  metaTitle: string = '';
  metaDescription: string = '';

  // TIERS
  selectedMenu = { tiers: false, stories: false, downloads: false, any: false };
  tiers; searchableTiers; 
  search: string = '';
  
  error: string = null;

  data = [];
  selectedData;
  dates;
  selectedDate;
  stories;
  storyParts;
  selectedStory;
  storyTitle;
  storyInfo = '';
  maps;
  mapTypes = [];
  selectedMap;
  selectedMapType;
  selectedCategory = null;
  selectedGrouping = null;
  selectedIndicator = null;
  indicatorFlatten = [];
  sourceGrouping;
  sourceIndicator;
  grouping = [];
  indicator = [];
  displayMenu = false;
  chartName;
  chartDetail;
  chartData;
  previousPoint;
  lastFeature;
  selectedPoint;
  tabularData = [];
  dataDecimal = '1.0-0';

  mapboxMap: Map;
  mapboxOptions: any;
  mapboxMissingOptions: any;
  mbHoverFilter = ['==', 'onsCode', ''];

  chart: any;
  currentChart: number = 0;
  chartOptions;
  charts: any = [];
  showChart: boolean = false;
  showTabular: boolean = false;
  showMenu: boolean = true;
  showReset: boolean = false;
  showHelp: boolean = false;
  showAdvancedData: boolean = true;
  showInfo;
  ccgOrIcs: string = 'ccg';

  urlParams = { chart: null, tier: null, date: null, map: null, story: null };
  copied: boolean = false;
  show: boolean = true;
  zoom: number;
  center: any;
  averages: {
    min: any;
    max: any;
    mean: number;
    median: any;
    count: any;
    quintile_20: any;
    quintile_40: any;
    quintile_60: any;
    quintile_80: any;
  };

  colours = [
    { colour: '#000000' },
    { colour: '#3C4868' },
    { colour: '#596C9B' },
    { colour: '#8A98BC' },
    { colour: '#BEC6DA' },
  ];
  apiColours;
  apiColourBlindAlternative = 'N';
  showColourBlind: boolean = false;
  showLegend: boolean = false;

  defaultZoom = 5.68731479884877;
  defaultCenter = [-7.59790211519055, 53.279491892429564];

  storyMode: boolean = false;
  minCounter: number = 1;
  maxCounter: number;
  counter: number = 1;

  missingAreas: any;
  showMissingAreas: boolean = false;
  showMsg: boolean = false;
  isMobile: boolean = false;
  startY: number;
  calcY: number;
  yPosition: number;
  element: any;
  flattenedTierArray: any = []
  currentObject: any
  showCodeBlock: boolean = false;
  currentChartId: number;

  // Downloads
  @ViewChild('dlChart') dlChart: ElementRef;
  @ViewChild('dlMap') dlMap: ElementRef;
  @ViewChild('dlCanvas') dlCanvas: ElementRef;
  @ViewChild('dlLink') dlLink: ElementRef;
  dlShow: boolean = false;
  dlReady: boolean = false;
  dlDate: any;
  dlHighlighted: any;
  last_index = 150;
  textCounter = 150;
  showTxt = '[Show More]';
  textOpen = true;
  focusSearch: boolean;
  slug: string;
  reverseDateSearch: any;
  dateIdFromSlug: number;
  reverseChartSearch: any;

  mousePosition: any;
  offset: Array<any> = [0, 0];
  isDown: boolean = false;
  lineView: boolean;

  selectOptionExtraText: string; 

  glossaryTerms
  glossaryKeyTerms: any[] = [];
  openGlossary: boolean = false;
  selectedTermDescription;
  
  public visibleChart: string = 'column';
  public organisationList: Org[];
  public selectedOrganisationCode: string;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public route: ActivatedRoute,
    public router: Router,
    public unique: UniquePipe,
    public flatten: FlattenPipe,
    public orderBy: OrderByPipe,
    private zsaService: ZsaService,
    private captureService: NgxCaptureService,
    private metatagsService: MetatagsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private glossaryService: GlossaryService   
    ) { }

  ngOnInit() : void {
    this.dateIdFromSlug = this.activatedRoute.snapshot.params.dateSlug
    this.slug = this.activatedRoute.snapshot.params.chartSlug
    this.activatedRoute.queryParams.subscribe(params => {
      this.urlParams.story = +params.story;
      this.selectedOrganisationCode = params.org ? params.org : null;
    });
    if (document.getElementById('contentInnerMobile')) {
      this.dragElement(document.getElementById('contentInnerMobile'));
    }

    this.mapDefault();
    this.getTierList();
    this.detectChrome()
  }

  detectChrome() {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      this.selectOptionExtraText = '&nbsp;&nbsp;&nbsp;&nbsp;'
    } else {
      this.selectOptionExtraText = ''
    }
    this.getGlossaryTerms();
  }

  getGlossaryTerms() {
    this.glossaryService.getGlossaryTermsList().subscribe(r => {
      this.glossaryTerms = r.data.glossaryDefinitionList
      this.glossaryTerms.forEach(t => this.glossaryKeyTerms.push(t.keyTerm)) 
    },
    (error) => {
      console.error('Error:', error);
    })
  }

  createChartDescriptionGlossaryLinks(chartDetail) {
      this.glossaryKeyTerms.forEach(term => {        
        const regex = new RegExp(`(${term})`, 'gi');
        const link = `<span style="color: #48587F; text-decoration: underline; text-decoration-style: dashed; cursor: help" class="glossaryLink" data-term="${term}">$1</span>`    
        chartDetail.narrative= chartDetail.narrative.replace(regex, link);    
      });
      this.chartDetail = chartDetail;    
      setTimeout(()=> {
        const glossaryLinks = document.querySelectorAll('.glossaryLink')
        glossaryLinks.forEach(link => {
          if (link) {
            link.addEventListener('click', (event) => this.openGlossaryPopup(event));
          }       
        })       
      },1000)       
  }

  openGlossaryPopup(event: Event) {
    document.addEventListener('click', (event) => this.handleClickOutsideGlossaryPopup(event));
    this.openGlossary = true;
    const term = (event.target as HTMLElement).dataset.term;
    const glossaryTerm = this.glossaryTerms.find(t=>t.keyTerm == term)
    this.selectedTermDescription =  glossaryTerm.termDescription
  }

  handleClickOutsideGlossaryPopup(event: Event) {
    const target = event.target as HTMLElement;
    const glossaryPopup =  document.querySelector('.glossaryPopup')
    if (!target.closest('.glossaryPopup') && !target.closest('.glossaryLink')) {      
      this.openGlossary = false
    }
  }

  getTierList() {
    this.zsaService.TierList().subscribe((r) => {
      let tierList = r.data.tierTree;
      this.tiers = tierList;
      this.groupingTiers(this.slug);
      this.searchableTiers = this.formatTiers(tierList);
      this.getStoryList();
      this.getFlatTierList();
    });
  }

  groupingTiers(slug) {
    this.tiers.forEach(item => {
      item.children.forEach(child => {
        this.grouping.push(child);
        child.children.forEach(indi => {
          if(indi.chartId !== null) {
            this.indicator.push(indi)
            this.indicatorFlatten.push(indi)
            if(indi.chartSlug == slug) {
              this.selectedCategoryFilter(item.tierId);
              this.selectedGroupingFilter(child.tierId);
              this.selectedIndicator = indi.tierId;
            }
          }
        })
      })
    })
    this.sourceGrouping = this.grouping;
    this.sourceIndicator = this.indicator;
  }

  getFlatTierList() {
    this.zsaService.FlatTierList().subscribe((chart) => {
      this.flattenedTierArray = chart.data.tierList;
      if(!this.reverseChartSearch){
        this.refreshCheck();
      }
      let index = 0
      this.flattenedTierArray.forEach(t => t.order = index++)
      this.currentObject = this.flattenedTierArray[this.flattenedTierArray.order];
      let formattedTierArray = [];
      this.flattenedTierArray.forEach(item => {
        this.indicatorFlatten.forEach((tier, index) => {
          if(item.tierId == tier.tierId) {
            item.order = index;
            formattedTierArray.push(item)
          }
        })
      })
      this.flattenedTierArray = formattedTierArray;
    },
    (error) => {
      console.error('Error:', error);
     }
   );
}

  traverseArray(direction: string): void {
    let newCurrentChartId = this.chartDetail.chartId
    let matchedFlattenedArray = this.flattenedTierArray.filter(id => id.chartId == newCurrentChartId)

    if (direction === 'next') {
      let index = matchedFlattenedArray[0].order
      index++;
      this.currentObject = this.flattenedTierArray[index];
      if(this.currentObject == undefined){
        this.currentObject = this.flattenedTierArray[0];
      }
      let currentChartId = this.currentObject.chartId;
      this.getChartDetail(currentChartId, this.currentObject.tierId)

      } else if (direction === 'prev') {
        let index = matchedFlattenedArray[0].order
        index--;
        this.currentObject = this.flattenedTierArray[index];
        if(this.currentObject == undefined){
          this.currentObject = this.flattenedTierArray[this.flattenedTierArray.length - 1];
        }
        let currentChartId = this.currentObject.chartId;
        this.getChartDetail(currentChartId, this.currentObject.tierId)
    }

    this.groupingTiers(this.currentObject.chartSlug);
  }

  selectedCategoryFilter(event) {
    this.grouping = this.sourceGrouping;
    this.grouping = this.tiers.filter(item => item.tierId == event);
    this.selectedCategory = event;
    this.grouping[0].children.forEach(child => {
      child.children = child.children.filter(min => min.chartId !== null)
    })

    this.grouping[0].children = this.grouping[0].children.filter(get => get.children.length > 0)
    this.selectedGrouping = null;
    this.selectedIndicator = null;
  }

  
  selectedGroupingFilter(event) {
    this.indicator = this.sourceIndicator;
    this.indicator = this.grouping[0].children.filter(item => item.tierId == event);
    this.selectedGrouping = event;
    this.selectedIndicator = null;
  }

  selectedIndicatorFilter(event, dropdown) {
    this.selectedIndicator = event;
    this.selectedFilter(event, dropdown)
  }

  selectedFilter(event, dropdown?) {
    sessionStorage.setItem(dropdown, event);
    let temp = this.indicator[0].children.filter(item => item.tierId == event)
    if(temp[0]?.chartId !== null) {
      this.getChartDetail(temp[0]?.chartId, temp[0]?.tierId);
    }
  }

  resetFilters() {
    this.selectedCategory = null;
    this.selectedGrouping = null;
    this.selectedIndicator = null;
    sessionStorage.removeItem("selectedCategory");
    sessionStorage.removeItem("selectedGrouping");
    sessionStorage.removeItem("selectedIndicator");
  }

  getChartDetail(
    chartId: number,
    tierId?: number,
    storyId?: number,
    reload?: boolean
  ) {

    this.urlParams.story = storyId
    this.setMenu('any');
    this.unsubscribe();
    this.currentChart = +chartId;
    this.error = null;

    this.zsaService.ChartDetail(chartId).subscribe(
      (a) => {
        let chartDetail = a.data.chartDetail;
        this.dates = this.orderBy.transform(chartDetail.dates, '-dateFrom');
        this.maps = chartDetail.maps;
        this.mapTypes = [];
        this.showCodeBlock = true;

        this.maps.forEach((element) => {
          if (this.mapTypes.includes(element.organisationType)) {
            return;
          }
          this.mapTypes.push(element.organisationType);
        });

        // this.chartDetail = chartDetail;
        this.createChartDescriptionGlossaryLinks(chartDetail);
        this.setSlug()

        this.metaTitle = this.chartDetail.title
        this.metaDescription = this.chartDetail.narrative.split('<a')[0]
        this.metatagsService.updateMetaTags(this.metaTitle, this.metaDescription)
        this.googleAnalyticsService.reportEvent('Reports', "click", this.chartDetail.title);
        this.selectedDate = this.dates[0].dateId;

        this.selectedMapType = this.mapTypes[0];

        this.selectedMap = this.maps.filter(
          (m) =>
            m.organisationType == this.selectedMapType &&
            m.dateId == this.selectedDate
        )[0].name;

        this.apiColours = chartDetail.colours.reverse();
        this.apiColourBlindAlternative = chartDetail.blindAlternative;

        if (chartDetail.decPlaces > 0) {
          this.dataDecimal = '1.0-' + chartDetail.decPlaces;
        } else {
          this.dataDecimal = '1.0-0';
        }

        if (reload && this.dates.filter(d => d.dateId == this.urlParams.date).length > 0) {
          this.reverseDateSearch = this.chartDetail.dates.find(a => a.dateSlug == this.dateIdFromSlug);
          this.selectedDate = this.reverseDateSearch.dateId
        } else {
          this.selectedDate = this.dates[0].dateId;
        }

        // Save current date array for download
        this.dlDate = this.dates.find((d) => d.dateId === this.selectedDate);

        //Get orgTypeId for data table
        let orgType = this.chartDetail.orgTypeDates.find(a => a.organisationTypeName == this.selectedMapType);

        if(!this.dateIdFromSlug && !this.urlParams.story){
          this.getReportData(chartId, this.dates[0].dateId, tierId, storyId, orgType.organisationTypeID);
        }
        //CHECK IF URL SLUG IS SAME AS CHARTDETAIL SLUG BUT MAYBE NOT NEEDED 
        else if (this.dateIdFromSlug && this.slug == this.chartDetail.chartSlug && !this.urlParams.story) {
          let orgTypeDates = this.orderBy.transform(this.chartDetail.orgTypeDates, ['dateId', '-organisationTypeID']);
          this.reverseDateSearch = orgTypeDates.find(a => a.dateSlug == this.dateIdFromSlug);
          this.getOrganisationList(this.reverseDateSearch.organisationTypeID);
          this.getReportData(chartId, this.reverseDateSearch.dateId, tierId, storyId, this.reverseDateSearch.organisationTypeID);
          this.router
          .navigate(['maps', this.chartDetail.chartSlug, this.dateIdFromSlug], {queryParamsHandling: 'merge'})
          .then();
        }
        else if (this.dateIdFromSlug && this.slug == this.chartDetail.chartSlug && this.urlParams.story){
          this.reverseDateSearch = this.chartDetail.dates.find(a => a.dateSlug == this.dateIdFromSlug)
          this.getReportData(chartId, this.reverseDateSearch.dateId, tierId, this.urlParams.story, orgType.organisationTypeID);
          this.router
          .navigate(['maps', this.chartDetail.chartSlug, this.dateIdFromSlug], {queryParams: { story: this.urlParams.story } })
          .then();
        } 
        else {
          this.dateIdFromSlug = null
        this.getReportData(chartId,  this.dates[0].dateId, tierId, storyId, orgType.organisationTypeID);
        }

        this.urlParams.tier = tierId
        this.urlParams.chart = chartId
        this.urlParams.date = this.chartDetail.dates[0].dateId
        this.urlParams.map = this.chartDetail.maps[0].mapId
        // setTimeout(() => {
        //   if(document.getElementById('contentInnerMobile')){
        //     this.dragElement(document.getElementById('contentInnerMobile'));
        //   }
        // }, 1000);

      },
      error => {
        this.error = error.error.message;
      }
    )
  }

  getOrganisationList(orgTypeId: number): void {
    this.zsaService.Organisations(orgTypeId).subscribe(
      success => {
        this.organisationList = success.data.orgTypesData;
      },
      error => {
        console.log(error);
      }
    )
  }

  getReportData(chartId: number, dateId: number, tierId?: number,  storyId?: number, orgTypeId?: number) {
    // Save as param    
    // if (!tierId && storyId) {
    //   this.router.navigate([], { 
    //     relativeTo: this.route, 
    //     queryParams: { tier: null, chart: chartId, date: dateId, story: storyId }, 
    //     queryParamsHandling: 'merge'
    //   });
    // }
    // else {
    //   this.router.navigate([], { 
    //     relativeTo: this.route, 
    //     queryParams: { tier: tierId, chart: chartId, date: dateId, story: null }, 
    //     queryParamsHandling: 'merge'
    //   });
    // }
    
    this.subscriptions.push(
      this.zsaService.ChartData(chartId, dateId, orgTypeId).subscribe(
        (a) => {
          this.chartData = a.data.chartData;

          const selectedMap = this.maps.find(
            (m) =>
              m.organisationType == this.selectedMapType &&
              m.dateId == this.selectedDate
          );
          if (selectedMap) {
            this.selectedMap = selectedMap.name;
            this.createMap(this.selectedMap, this.chartData);
          } else {
            this.mapboxMap = null;
            this.error = 'No map available for this time period.';
          }
        },
        (error) => {
          this.error = error.error.message;
        }
      )
    );
  }

  getStoryList() { 
    this.zsaService.StoryList().subscribe((r) => {
      let storyList = r.data.storyList;

      this.stories = storyList;
    });
  }

  getStoryDetail(storyId: number, chartId?: number) {
    this.zsaService.StoryDetail(storyId).subscribe(
      r => {
        let storyDetail = r.data.story;
        this.selectedStory = storyId;

      this.storyTitle = this.stories.filter(
        (s) => s.storyId == storyId
      )[0].name;
      this.storyInfo = this.stories.filter(
        (s) => s.storyId == storyId
      )[0].description;

      this.last_index = this.storyInfo.substring(0, 150).lastIndexOf(' ');

      if (this.last_index > 150) this.last_index = 150;

      this.textCounter = this.last_index;

      this.storyParts = storyDetail;
      this.maxCounter = this.storyParts.length;
      let chosenReport = this.storyParts[0].chartId;

      if (chartId) {
        chosenReport = chartId;
        this.counter =
          this.storyParts.map((m) => m.chartId).indexOf(chartId) + 1;
      }

      this.getChartDetail(chosenReport, null, storyId);
    });
  }

  toggleStoryIntro() {
    if (this.textCounter < 151) {
      this.textCounter = this.storyInfo.length;
      this.textOpen = false;
      this.showTxt = ' [Show less]';
    } else {
      this.textCounter = this.last_index;
      this.textOpen = true;
      this.showTxt = ' [Show more]';
    }
  }

  dateChange(dateId: any) {
    this.selectedDate = +dateId;
    this.dlDate = this.dates.find(d => d.dateId === this.selectedDate);
    let index = this.dates.findIndex(d => d.dateId === this.selectedDate);

    if (!this.urlParams.story){
      this.router
        .navigate(['maps', this.activatedRoute.snapshot.params.chartSlug, this.chartDetail.dates[index].dateSlug], {queryParamsHandling: 'merge'})
        .then();
      this.reverseDateSearch = this.chartDetail.orgTypeDates.find(a => a.dateSlug == this.chartDetail.dates[index].dateSlug);
      this.getOrganisationList(this.reverseDateSearch.organisationTypeID);
      this.getReportData(this.urlParams.chart, dateId, this.urlParams.tier);
    } else if (this.urlParams.story) {
      this.router
      .navigate(['maps', this.activatedRoute.snapshot.params.chartSlug, this.chartDetail.dates[index].dateSlug], {replaceUrl: true, queryParams: { story: this.urlParams.story } })
      .then();
      this.getReportData(this.urlParams.chart, dateId, this.urlParams.tier);
    }
  }

  public organisationChange(organisationCode: string): void {
    this.selectedOrganisationCode = organisationCode;
    this.highlightOrganisationOnChart(organisationCode);
  }

  public highlightOrganisationOnChart(organisationCode: string): void {
    // Check organisation is in orgList
    let check = this.organisationList.find(org => org.code == organisationCode);
    if (check && this.chart) {
      // Highlight point
      let chartData = this.chart['series'][0]['data'];
      chartData.forEach(point => point.select(false, true))
      let point = chartData.find(data => data.category == organisationCode);
      if (point) {
        point.select(true, true);
      }
      // Add to URL
      this.router.navigate([], { queryParams: { org: organisationCode }, queryParamsHandling: 'merge' });
    } else {
      this.selectedOrganisationCode = null;
      this.router.navigate([], { queryParams: { org: null }, queryParamsHandling: 'merge' });
    }
  }

  mapChange(mapType: string) {
    this.selectedMapType = mapType;
    let orgType = this.chartDetail.orgTypeDates.find(a => a.organisationTypeName == this.selectedMapType)
    this.getReportData(this.urlParams.chart, this.selectedDate, this.urlParams.tier, this.urlParams.story, orgType.organisationTypeID);
  }

  changeIndicator(counter: number) {
    let count;

    if (counter > 0) {
      count = this.counter + 1;
    } else if (counter < 0) {
      count = this.counter - 1;
    }

    this.counter = count;

    this.getChartDetail(
      this.storyParts[count - 1].chartId,
      null,
      this.storyParts[count - 1].storyId
    );
  }

  createMap(mapType, results) {
    let mapUrl = 'assets/maps/map_' + mapType + '.json';
    let allOrgs = this.maps.filter((m) => m.name == mapType)[0].organisations;

    this.data = [];
    this.tabularData = [];

    if (this.currentChart == 26 || this.currentChart == 27) {
      this.showAdvancedData = false;
    } else {
      this.showAdvancedData = true;
    }

    this.http.get(mapUrl).subscribe(
      (geojson: any) => {
        // Iterate over data and assign data to relevant objects
        results.forEach((r) => {
          // TODO: These two loops can be one data = tabularData
          this.data.push({
            organisationCode: r.code,
            organisationName: r.name,
            submissionResult: r.result,
          });

          this.tabularData.push({
            organisationId: r.organisationId,
            organisationCode: r.code,
            organisationName: r.name,
            value: r.result,
          });
        });

        // Set averages
        this.averages = this.setAverages(
          this.data.map((a) => a.submissionResult)
        );

        // Set advanced data
        if (this.showAdvancedData) {
          // Set averages to colours
          for (let index = 0; index < this.apiColours.length; index++) {
            const element = this.apiColours[index];
            if (index == 4) {
              element['value'] = this.averages.max;
            }
            if (index == 0) {
              element['value'] = this.averages.min;
            }
          }
        }

        // Assign data to GeoJSON
        geojson.features.forEach((g) => {
          let organisation;

          organisation = this.data.filter(
            (c) => c.organisationCode === g.properties.onsCode
          )[0];

          if (organisation) {
            g.properties.value = organisation.submissionResult || 0;
          }
        });

        // Find areas without data
        let missingAreas = [];
        allOrgs.forEach((o) => {
          let match = this.data.find((d) => o.code === d.organisationCode);
          if (!match) {
            missingAreas.push(o);
          }
        });

        // Display missing areas
        // TODO: Ensure this only shows areas without any fill on map
        this.missingAreas = missingAreas;

        // Add layer to Mapbox for missing areas
        let missingGeojson: any = { type: 'FeatureCollection', features: [] };
        missingAreas.forEach((ma) => {
          let match = geojson.features.find(
            (gf) => ma.code === gf.properties.onsCode
          );
          if (match) {
            missingGeojson.features.push(match);
          }
        });

        // Build Mapbox options
        if (this.showAdvancedData) {
          let colour = ['interpolate', ['exponential', 0], ['get', 'value']];
          let quintiles = [
            this.averages.quintile_20,
            this.averages.quintile_40,
            this.averages.quintile_60,
            this.averages.quintile_80,
            this.averages.max,
          ];
          this.apiColours.forEach((c, i) => {
            if (this.apiColourBlindAlternative == 'Y' && this.showColourBlind) {
              colour.push(quintiles[i], c.blindColour);
            } else {
              colour.push(quintiles[i], c.colour);
            }
          });

          this.mapboxOptions.paint = {
            'fill-color': colour,
            'fill-outline-color': '#FFFFFF',
            'fill-opacity': 0.75,
          };
        } else {
          this.mapboxOptions.paint = {
            'fill-color': '#48587F',
            'fill-outline-color': '#FFFFFF',
            'fill-opacity': 0.75,
          };
        }

        if (this.mapboxMap) {
          let organisationAreas: mapboxgl.GeoJSONSource =
              this.mapboxMap.getSource(
                'organisation_areas'
              ) as mapboxgl.GeoJSONSource,
            organisationAreasHover: mapboxgl.GeoJSONSource =
              this.mapboxMap.getSource(
                'organisation_areas_hover'
              ) as mapboxgl.GeoJSONSource,
            organisationAreasMissing: mapboxgl.GeoJSONSource =
              this.mapboxMap.getSource(
                'organisation_areas_missing'
              ) as mapboxgl.GeoJSONSource;

          organisationAreas.setData(geojson);
          organisationAreasHover.setData(geojson);
          organisationAreasMissing.setData(missingGeojson);

          let width = window.innerWidth;
          let zoomLevel;
          let setCenter;

          // Mobile devices
          if (width <= 480) {
            this.lineView = true
            this.isMobile = true;
            zoomLevel = 5;
            setCenter = new mapboxgl.LngLat(-2.09790211519055, 51);
            // iPads, Tablets
          } else if (width >= 481 && width <= 768) {
            this.lineView = true
            zoomLevel = 5.5;
            setCenter = new mapboxgl.LngLat(-2.09790211519055, 53);
            // Small screens, laptops
          } else if (width >= 769 && width <= 1024) {
            zoomLevel = 6.2;
            setCenter = new mapboxgl.LngLat(-2.09790211519055, 53);
            // Desktops, large screens
          } else if (width >= 1025) {
            zoomLevel = 5.68731479884877;
            setCenter = new mapboxgl.LngLat(
              -7.59790211519055,
              53.279491892429564
            );
          }

          this.mapboxMap.setZoom(zoomLevel);
          this.mapboxMap.setCenter(setCenter);
        }
        if(this.isMobile && this.show) {
          setTimeout(() => {
            this.dragElement(document.getElementById('contentInnerMobile'));
          }, 100);
        }


      },
      (e) => {
        console.log('No map', e);
      }
    );
  }

  loadChart(map) {
    if (map.isSourceLoaded && map.sourceId === 'organisation_areas') {
      this.showLegend = true;
      this.createColumn(
        this.averages,
        this.data,
        this.selectedMap.title,
        this.chartDetail
      );
      this.showChart = true;
      this.showTabular = false;
    }
  }

  getMapInfo() {
    this.zoom = this.mapboxMap.getZoom();
    this.center = this.mapboxMap.getCenter();
  }

  checkZoom(event) {
    if (!this.showChart) return;

    let currentZoom = event.transform._zoom;

    if (currentZoom != this.defaultZoom && this.showChart) {
      this.showReset = true;
    }
  }

  checkMove(event) {
    if (!this.showChart) return;

    let currentMove = event.transform._center;

    if (
      currentMove.lat != this.defaultCenter[1] ||
      currentMove.lng != this.defaultCenter[0]
    ) {
      this.showReset = true;
    }
  }

  reset() {
    this.currentChart = 0;
    this.showChart = false;
    this.showTabular = false;
    this.showMenu = true;
    this.showLegend = false;
    this.showReset = false;
    this.storyMode = false;
    this.selectedMap = false;
    this.counter = 1;
    
    this.selectedCategory = null;
    this.selectedGrouping = null;
    this.selectedIndicator = null;
    sessionStorage.removeItem("selectedCategory");
    sessionStorage.removeItem("selectedGrouping");
    sessionStorage.removeItem("selectedIndicator");

    this.router
    .navigate(['maps'], {replaceUrl: true})
    .then();
    
    this.mapboxMap.setZoom(this.defaultZoom);
    this.mapboxMap.setCenter([-7.09790211519055, 52.979491892429564]);

    this.mapboxMap
      .removeLayer('organisation_areas')
      .removeSource('organisation_areas');
    this.mapboxMap
      .removeLayer('organisation_areas_hover')
      .removeSource('organisation_areas_hover');
    this.mapboxMap
      .removeLayer('organisation_areas_missing')
      .removeSource('organisation_areas_missing');
  }

  resetView() {
    this.mapboxMap.setZoom(this.defaultZoom);
    this.mapboxMap.setCenter([-7.09790211519055, 52.979491892429564]);

    this.showReset = false;
  }

  mapDefault() {
    this.mapboxOptions = {
      style: 'mapbox://styles/dhughesbmc/ck49sb7ni05c51cn4axz029os',
      zoom: this.defaultZoom,
      center: this.defaultCenter,
      type: 'fill',
      logoPosition: 'top-right',
      paint: {
        'fill-outline-color': '#FFFFFF',
        'fill-opacity': 0.75,
      },
      hover: {
        'line-color': '#000000',
        'line-width': 1,
        'line-opacity': 0.75,
      },
      source: {
        type: 'geojson',
        data: null,
      },
      preserveDrawingBuffer: true,
    };
    this.mapboxMissingOptions = {
      type: 'fill',
      paint: { 'fill-pattern': 'hash_bg' },
      source: { type: 'geojson', data: null },
    };
  }

  createColumn(averages, data, chartType, chartDetails) {
    this.charts = [];
    this.dlHighlighted = null;
    let sortedData = data.sort(
      (b, a) => a.submissionResult - b.submissionResult
    );
    let colour = [];
    let plotlines = [];

    if (this.showAdvancedData) {
      let quintiles = [
        this.averages.quintile_20 + 0.001,
        this.averages.quintile_40 + 0.001,
        this.averages.quintile_60 + 0.001,
        this.averages.quintile_80 + 0.001,
        this.averages.max + 1,
      ];
      this.apiColours.forEach((c, i) => {
        if (this.apiColourBlindAlternative == 'Y' && this.showColourBlind) {
          colour.push({ color: c.blindColour, value: quintiles[i] });
        } else {
          colour.push({ color: c.colour, value: quintiles[i] });
        }
      });
    }

    plotlines = [
      {
        color: '#009639',
        value: averages.median,
        width: '2',
        zIndex: 10,
      },
    ];

    this.chartOptions = {
      title: { text: '' },
      tooltip: { enabled: false },
      exporting: {
        enabled: false,
        chartOptions: {
          title: {
            text: chartDetails.title,
          },
          credits: {
            enabled: true,
            text: 'Zero Suicide Alliance',
            href: 'https://www.zerosuicidealliance.com/',
          },
        },
      },
      chart: {
        animation: false,
        events: {
          load: (e) => {
            this.chart = e.target;
            this.charts.push(e.target);
            this.highlightOrganisationOnChart(this.selectedOrganisationCode);
          },
        },
        style: { fontFamily: 'Helvetica, Arial, sans-serif' },
        backgroundColor: 'transparent',
      },
      colors: ['#48587F'],
      legend: false,
      credits: false,
      yAxis: {
        max: chartDetails.maxValue,
        gridLineColor: '#EEF1F8',
        title: {
          text: chartDetails.yAxis,
        },
        labels: {
          style: {
            color: '#48587F',
          },
          formatter: (e) => {
            if (chartDetails.format === 'P') {
              return e.value + '%';
            } else {
              return e.value;
            }
          },
        },
        plotLines: plotlines,
      },
      xAxis: {
        categories: sortedData.map((r) => r.organisationCode),
        title: {
          text: chartType,
          margin: 10,
          style: {
            color: '#48587F',
          },
        },
        crosshair: true,
        labels: { enabled: false },
      },
      plotOptions: {
        series: {
          animation: false,
          point: {
            events: {
              mouseOver: (e) => {
                this.chartMouseOver(e, 'chart');
              },
              mouseOut: (e) => {
                this.chartMouseOut();
              },
            },
          },
        },
        column: {
          borderWidth: 0,
        },
      },
      series: [
        {
          type: 'column',
          data: sortedData.map((r) => r.submissionResult),
          zones: colour,
          states: {
            hover: {
              borderColor: '#000000',
              borderWidth: 1,
            },
            inactive: {
              color: '#EEF1F8',
              opacity: 1,
            },
            select: {
              color: '#000000',
            },
          },
        },
      ],
    };
  }

  setAverages(inputData) {
    let sortedData = inputData.sort((a, b) => {
        return a - b;
      }),
      half = Math.floor(sortedData.length / 2),
      median,
      count = sortedData.length,
      average = sortedData.reduce((a, b) => a + b, 0) / count,
      per20 = Math.ceil(count * 0.2) - 1,
      per40 = Math.ceil(count * 0.4) - 1,
      per60 = Math.ceil(count * 0.6) - 1,
      per80 = Math.ceil(count * 0.8) - 1;

    if (sortedData.length % 2) {
      median = sortedData[half];
    } else {
      median = (sortedData[half - 1] + sortedData[half]) / 2.0;
    }

    return {
      min: sortedData.reduce((a, b) => Math.min(a, b)),
      max: sortedData.reduce((a, b) => Math.max(a, b)),
      mean: average,
      median: median,
      count: count,
      quintile_20: sortedData[per20],
      quintile_40: sortedData[per40],
      quintile_60: sortedData[per60],
      quintile_80: sortedData[per80],
    };
  }

  aggregateResults(inputData, allOrganisations, organisationTypeName) {
    //, availableDates
    let relationships = [],
      aggregatedData = [],
      averageData = [],
      organisations;

    organisations = allOrganisations.filter((o) => o.organisationTypeId === 1);

    // Set relationships
    organisations.forEach((o) => {
      if (o.organisationRelationships['ICS to CCG']) {
        o.organisationRelationships['ICS to CCG'].forEach((oo) => {
          relationships.push({
            organisationCode: o.organisationCode,
            organisationName: o.organisationName,
            icsCode: oo.organisationCode,
            icsName: oo.organisationName,
            factor: oo.relationshipFactor,
            proportion: oo.relationshipProportion,
          });
        });
      }
    });

    // Set factors and proportions for all data
    inputData.forEach((d) => {
      relationships.forEach((r) => {
        if (r.organisationCode === d.organisationCode) {
          aggregatedData.push({
            uniqueId: d.dateId + '-' + r.icsCode,
            dateId: d.dateId,
            dateName: d.dateName,
            organisationName: d.organisationName,
            organisationCode: d.organisationCode,
            submissionName: d.submissionName,
            submissionCode: d.submissionCode,
            submissionResult: d.submissionResult,
            factor: r.factor,
            proportion: r.proportion,
            icsCode: r.icsCode,
            icsName: r.icsName,
            factoredResult: d.submissionResult * r.factor,
            proportionedResult: d.submissionResult * r.proportion,
          });
        }
      });
    });

    // // Every ICS in every period
    // let parents = this.unique.transform(aggregatedData, 'uniqueId');

    // // Push child areas to ICS parents
    // parents.forEach(p => {
    //   p.children = [];
    //   aggregatedData.forEach(a => {
    //     if (a.uniqueId === p.uniqueId) {
    //       p.children.push({
    //         organisationName:      a.organisationName,
    //         organisationCode:   a.organisationCode,
    //         submissionName:        a.submissionName,
    //         submissionCode:        a.submissionCode,
    //         submissionResult:      a.submissionResult,
    //         factoredResult:        a.factoredResult,
    //         proportionedResult:    a.proportionedResult
    //       });
    //     };
    //   });
    // });

    // // Map to a usable final array
    // aggregatedData = parents.map(i => {
    //   return {
    //     dateId:                 i.dateId,
    //     dateName:               i.dateName,
    //     organisationCode:    i.icsCode,
    //     organisationName:       i.icsName,
    //     children:               i.children,
    //     submissionResult:       i.children.map(c => c.proportionedResult).reduce((a, b) => a + b, 0),
    //     // TODO: Correctly use proportioned or factored result according to data quality
    //     // factoredResult:      i.children.map(c => c.factoredResult).reduce((a, b) => a + b, 0)
    //     status:                 'standard',
    //     colour:                 '#0D5EB8'
    //   };
    // });

    // // Highlight default
    // let defaultOnsCode = this.userDefaults[this.currentLevel].defaultOnsCode,
    //     defaultSubmission = aggregatedData.filter(o => o.organisationCode === defaultOnsCode)[0];

    // if (defaultSubmission) {
    //   defaultSubmission.status = 'default';
    //   defaultSubmission.colour = '#DA291C';
    // }

    // availableDates.forEach(a => {
    //   let periodData = aggregatedData.filter(d => d.dateId === a.dateId);
    //   let organisation = periodData.filter(d => d.organisationCode === this.defaults.defaultCode);
    //   let organisationResult;
    //   if (organisation[0]) {
    //     organisationResult = organisation[0].submissionResult;
    //   }
    //   averageData.push({
    //     dateId:           a.dateId,
    //     dateName:         a.dateName,
    //     nationalAverage:  this.setAverages(periodData.map(d => d.submissionResult)),
    //     organisation:     organisationResult
    //   });
    // });

    // return { outputData: aggregatedData, averageData: averageData };
  }

  aggregateData(
    inputData,
    loadedLevel,
    dataQuality,
    factor,
    linkedOrganisations
  ) {
    let linkOrganisations = [];
    let temp = [];
    let data = [];
    let icsList = [];

    linkOrganisations = linkedOrganisations;

    linkOrganisations.forEach((l) => {
      if (inputData.filter((c) => c.onsCode === l.subOnsCode)[0]) {
        temp.push({
          subName: l.subName,
          subOnsCode: l.subOnsCode,
          icsOnsCode: l.icsOnsCode,
          icsName: l.icsOrganisationName,
          response: inputData.filter((c) => c.onsCode === l.subOnsCode)[0]
            .value,
          numeratorTotal: inputData.filter((c) => c.onsCode === l.subOnsCode)[0]
            .numeratorTotal,
          denominatorTotal: inputData.filter(
            (c) => c.onsCode === l.subOnsCode
          )[0].denominatorTotal,
          factor: l.factor,
          proportion: l.proportion,
        });
      }
    });

    temp.forEach((d) => {
      d.proportionedValue = d.response * d.proportion;
      d.factoredNumerator = d.numeratorTotal * d.factor;
      d.factoredDenominator = d.denominatorTotal * d.factor;
    });

    icsList = this.unique.transform(temp.map((d) => d.icsOnsCode));

    icsList.forEach((i) => {
      data.push({
        onsCode: i,
        name: temp.filter((d) => d.icsOnsCode === i)[0].icsName,
        responses: temp
          .filter((d) => d.icsOnsCode === i)
          .map((r) => {
            return {
              onsCode: r.subOnsCode,
              name: r.subName,
              numeratorTotal: r.numeratorTotal,
              denominatorTotal: r.denominatorTotal,
              factoredNumerator: r.factoredNumerator,
              factoredDenominator: r.factoredDenominator,
              value: r.response,
              proportionedValue: r.proportionedValue,
            };
          }),
      });
    });

    // if (this.highlightCode) {
    //   let areaData = data.filter(d => d.onsCode === this.highlightCode)[0];
    //   if (areaData) {
    //     this.areaData = areaData.responses;
    //   }
    // }

    data.forEach((d) => {
      d.subCount = d.responses.length;

      // CCG or LA to ICS when calculated: SUM(NP) -- OK
      if (dataQuality.includes('calculated', 'estimated')) {
        d.numeratorTotal = d.responses
          .map((r) => r.proportionedValue)
          .reduce((a, b) => a + b, 0);
        d.denominatorTotal = 1;
      }

      // CCG to ICS when raw: SUM(N/D)
      else if (
        loadedLevel === 'ccg' &&
        dataQuality.includes('raw', 'suppressed')
      ) {
        d.numeratorTotal = d.responses
          .map((r) => r.numeratorTotal)
          .reduce((a, b) => a + b, 0);
        d.denominatorTotal = d.responses
          .map((r) => r.denominatorTotal)
          .reduce((a, b) => a + b, 0);
      }

      // LA to ICS when raw: SUM(NF/DF)
      else if (
        loadedLevel === 'la' &&
        dataQuality.includes('raw', 'suppressed')
      ) {
        d.numeratorTotal = d.responses
          .map((r) => r.factoredNumerator)
          .reduce((a, b) => a + b, 0);
        d.denominatorTotal = d.responses
          .map((r) => r.factoredDenominator)
          .reduce((a, b) => a + b, 0);
      } else {
        d.numeratorTotal = d.responses
          .map((r) => r.numeratorTotal)
          .reduce((a, b) => a + b, 0);
        d.denominatorTotal = d.responses
          .map((r) => r.denominatorTotal)
          .reduce((a, b) => a + b, 0);
      }

      d.value = (d.numeratorTotal / d.denominatorTotal) * factor;
    });

    return data;
  }

  mbMouseOverMap(event) {
    if (!this.showChart) {
      return;
    }

    let point = this.mapboxMap.queryRenderedFeatures(event.point)[0],
      chartData = this.chart['series'][0]['data'];

    // Set hover state on corresponding chart entry
    if (
      point &&
      point.properties.onsCode &&
      point.properties.onsCode !== this.lastFeature
    ) {
      if (this.lastFeature) {
        this.previousPoint = chartData.filter(
          (s) => s.category === this.lastFeature
        )[0];

        if (this.previousPoint) {
          this.previousPoint.setState('');
        }
      }

      this.lastFeature = point.properties.onsCode;

      this.selectedPoint = chartData.filter(
        (s) => s.category === point.properties.onsCode
      )[0];

      if (this.selectedPoint) {
        this.selectedPoint.setState('hover');
      }
    }

    // Remove hover when exiting features
    if (point && point.source === 'composite' && this.lastFeature) {
      this.previousPoint = chartData.filter(
        (s) => s.category === this.lastFeature
      )[0];

      if (this.previousPoint) {
        this.previousPoint.setState('');
      }
    }
  }

  mbMouseOver(event: any, onsCode?) {
    let selectedOnsCode;

    if (onsCode) {
      selectedOnsCode = onsCode;
    } else {
      selectedOnsCode = event.features[0].properties.onsCode;
    }

    this.mbHoverFilter = ['==', 'onsCode', selectedOnsCode];
    this.selectedData = this.data.filter(
      (p) => p.organisationCode === selectedOnsCode
    )[0];
  }

  mbMouseOut() {
    this.mbHoverFilter = ['==', 'onsCode', ''];
    this.selectedData = null;
  }

  chartMouseOver(event, type) {
    var selectedOnsCode;

    selectedOnsCode = event.target.category;

    this.mbMouseOver(null, selectedOnsCode);

    this.selectedData = this.data.filter(
      (p) => p.organisationCode === selectedOnsCode
    )[0];
  }

  chartMouseOut() {
    this.mbMouseOut();
  }

  toggleColourBlind() {
    this.showColourBlind = !this.showColourBlind;

    this.createMap(this.selectedMap, this.chartData);
    this.createColumn(
      this.averages,
      this.data,
      this.selectedMap.title,
      this.chartDetail
    );
  }

  // FORMATS
  formatTiers(tiers) {
    let outputData = [];

    tiers.forEach((t1) => {
      if (t1.children) {
        t1.children.forEach((t2) => {
          outputData.push({
            t1_tierId: t1.tierId,
            t1_title: t1.title,
            t1_mapTypes: t1.mapTypes,
            t2_tierId: t2.tierId,
            t2_title: t2.title,
            t2_mapTypes: t2.mapTypes,
            t2_visible: t2.visible,
            chartId: t2.chartId,
          });
          if (t2.children) {
            t2.children.forEach((t3) => {
              outputData.push({
                t1_tierId: t1.tierId,
                t1_title: t1.title,
                t1_mapTypes: t1.mapTypes,
                t2_tierId: t2.tierId,
                t2_title: t2.title,
                t2_mapTypes: t2.mapTypes,
                t2_visible: t2.visible,
                t3_tierId: t3.tierId,
                t3_title: t3.title,
                t3_mapTypes: t3.mapTypes,
                t3_visible: t3.visible,
                chartId: t3.chartId,
              });
              if (t3.children) {
                t3.children.forEach((t4) => {
                  outputData.push({
                    t1_tierId: t1.tierId,
                    t1_title: t1.title,
                    t1_mapTypes: t1.mapTypes,
                    t2_tierId: t2.tierId,
                    t2_title: t2.title,
                    t2_mapTypes: t2.mapTypes,
                    t2_visible: t2.visible,
                    t3_tierId: t3.tierId,
                    t3_title: t3.title,
                    t3_mapTypes: t3.mapTypes,
                    t3_visible: t3.visible,
                    t4_tierId: t4.tierId,
                    t4_title: t4.title,
                    t4_mapTypes: t4.mapTypes,
                    t4_visible: t4.visible,
                    chartId: t4.chartId,
                  });
                  if (t4.children) {
                    t4.children.forEach((t5) => {
                      outputData.push({
                        t1_tierId: t1.tierId,
                        t1_title: t1.title,
                        t1_mapTypes: t1.mapTypes,
                        t2_tierId: t2.tierId,
                        t2_title: t2.title,
                        t2_mapTypes: t2.mapTypes,
                        t2_visible: t2.visible,
                        t3_tierId: t3.tierId,
                        t3_title: t3.title,
                        t3_mapTypes: t3.mapTypes,
                        t3_visible: t3.visible,
                        t4_tierId: t4.tierId,
                        t4_title: t4.title,
                        t4_mapTypes: t4.mapTypes,
                        t4_visible: t4.visible,
                        t5_tierId: t5.tierId,
                        t5_title: t5.title,
                        t5_mapTypes: t5.mapTypes,
                        t5_visible: t5.visible,
                        chartId: t5.chartId,
                      });
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    // Create tier map
    outputData.forEach((o) => {
      o.tierIdMap = [
        o.t1_tierId,
        o.t2_tierId ? o.t2_tierId : null,
        o.t3_tierId ? o.t3_tierId : null,
        o.t4_tierId ? o.t4_tierId : null,
        o.t5_tierId ? o.t5_tierId : null,
      ];
      o.tierNameMap = [
        o.t1_title,
        o.t2_title ? o.t2_title : null,
        o.t3_title ? o.t3_title : null,
        o.t4_title ? o.t4_title : null,
        o.t5_title ? o.t5_title : null,
      ];
      o.tierMapType = [
        o.t1_mapTypes,
        o.t2_mapTypes ? o.t2_mapTypes : null,
        o.t3_mapTypes ? o.t3_mapTypes : null,
        o.t4_mapTypes ? o.t4_mapTypes : null,
        o.t5_mapTypes ? o.t5_mapTypes : null,
      ];
    });

    outputData.forEach((o) => {
      o.tierIdMap = o.tierIdMap.filter((t) => t !== null);
      o.tierNameMap = o.tierNameMap.filter((t) => t !== null);
      o.tierMapType = o.tierMapType.filter((t) => t !== null);
    });

    // Split tier types
    outputData.forEach((o) => {
      o.tierMapType = o.tierMapType[0] ? o.tierMapType[0].split(',') : null;
    });

    // Remove null charts
    outputData = outputData.filter((t) => t.chartId !== null);

    return outputData;
  }

  setMenu(type) {
    if (type === 'any') {
      Object.keys(this.selectedMenu).forEach((m) => {
        this.selectedMenu[m] = false;
      })
    } else if (this.selectedMenu.any && this.selectedMenu[type] && this.focusSearch){
      this.focusSearch = false
    } 
    else {
      this.selectedMenu.any = !this.selectedMenu.any;
      this.selectedMenu[type] = !this.selectedMenu[type];
    }
  }

  setLink(tierId, chartId) {
    this.copied = false;

    let tiers = this.searchableTiers.filter(
      (t) => t.chartId === +chartId && t.tierIdMap.includes(tierId)
    )[0].tierIdMap;

    if(tiers){
      let t1, t2, t3, t4, t5;
      t1 = this.tiers.filter(t => t.tierId === tiers[0])[0];
  
      if (t1) {
        t1.selected = true;
        if (tiers.length > 1) {
          t2 = t1.children.filter(t => t.tierId === tiers[1])[0];
          t2.selected = true;
          if (tiers.length > 2) {
            t3 = t2.children.filter(t => t.tierId === tiers[2])[0];
            t3.selected = true;
            if (tiers.length > 3) {
              t4 = t3.children.filter(t => t.tierId === tiers[3])[0];
              t4.selected = true;
              if (tiers.length > 4) {
                t5 = t4.children.filter(t => t.tierId === tiers[4])[0]
                t5.selected = true;
              }
            }
          }
        } 
      }
    }
    }

  copyClipboard(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  openStory(storyId: number) {
    this.setMenu('any');
    this.storyMode = true;

    this.getStoryDetail(storyId);
  }

  createDownloadMap(mapType, results, highlight?) {
    let mapUrl = 'assets/maps/map_' + mapType + '.json';
    let data = [];
    let allOrgs = this.maps.filter((m) => m.name == mapType)[0].organisations;

    this.http.get(mapUrl).subscribe(
      (geojson: any) => {
        results.forEach((r) => {
          data.push({
            organisationCode: r.code,
            organisationName: r.name,
            submissionResult: r.result,
          });
        });

        geojson.features.forEach((g) => {
          let organisation;

          organisation = data.filter(
            (c) => c.organisationCode === g.properties.onsCode
          )[0];

          if (organisation) {
            g.properties.value = organisation.submissionResult || 0;
            g.properties.data = true;
          }
        });

        // Find missing data for missing.json
        let missingAreas = [];
        allOrgs.forEach((o) => {
          let match = this.data.find((d) => o.code === d.organisationCode);
          if (!match) {
            missingAreas.push(o);
          }
        });

        let missingGeojson: any = { type: 'FeatureCollection', features: [] };
        missingAreas.forEach((ma) => {
          let match = geojson.features.find(
            (gf) => ma.code === gf.properties.onsCode
          );
          if (match) {
            missingGeojson.features.push(match);
          }
        });

        geojson.features = geojson.features.filter((f) => f.properties.data);

        // Build Mapbox map
        var map = new mapboxgl.Map({
          accessToken:
            'pk.eyJ1IjoiZGh1Z2hlc2JtYyIsImEiOiJjazQ5bXAxYW8wNmM2M2ZwY3N2aTR4emU3In0.7rhqvNQKGZ_ciuuxVAdV3A',
          container: 'mapContainer',
          style: 'mapbox://styles/dhughesbmc/ck49sb7ni05c51cn4axz029os',
          zoom: 5.25,
          center: [-2.291, 52.95],
          preserveDrawingBuffer: true,
        });

        let paint = this.mapboxOptions.paint;

        map.on('load', function () {
          map.addSource('organisations', {
            type: 'geojson',
            data: geojson,
          });
          map.addLayer({
            id: 'organisations',
            type: 'fill',
            source: 'organisations',
            paint: paint,
          });
          map.loadImage('/assets/hatch_xs.png', function (error, image) {
            if (error) throw error;
            map.addImage('hash_bg', image);
            map.addSource('organisations_missing_data', {
              type: 'geojson',
              data: missingGeojson,
            });
            map.addLayer({
              id: 'organisations_missing',
              type: 'fill',
              source: 'organisations_missing_data',
              paint: { 'fill-pattern': 'hash_bg' },
            });
          });

          // Highlight selected organisation
          if (highlight) {
            let highlightGeojson: any = {
              type: 'FeatureCollection',
              features: [],
            };
            highlightGeojson.features = geojson.features.filter(
              (f) => f.properties.onsCode === highlight
            );
            map.addSource('highlights', {
              type: 'geojson',
              data: highlightGeojson,
            });
            map.addLayer({
              id: 'highlights',
              type: 'line',
              source: 'highlights',
              paint: {
                'line-color': '#000000',
                'line-width': 2,
              },
            });
          }
        });

        // Allow layer time to load
        setTimeout(() => {
          this.dlReady = true;
        }, 2000);
      },
      (e) => {
        console.log('No map', e);
      }
    );
  }

  download(type) {
    // Show map or chart and set custom crop options for screen width >= 1060px 
    let element;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let cropOptions;
    let getImageSettings:any[];
    let coefficient = 1;
    let coefficientB = 1;
    if (screenWidth >= 1060 && screenWidth < 1200) {
      coefficient = 2
      coefficientB = 1.5
    }
    if (screenWidth >= 1200 && screenWidth < 1300) coefficient = 1.85
    if (screenWidth >= 1300 && screenWidth < 1450) coefficient = 1.75
    if (screenWidth >= 1450 && screenWidth < 1600) coefficient = 1.5
    if (screenWidth >= 1600 && screenWidth < 1920) coefficient = 1.25 
    if (screenWidth >= 1920) coefficientB = 0.8
    
   
    if (type === 'map') {
      element = this.dlMap.nativeElement;         
      cropOptions = {
        x: (screenWidth/(4*coefficientB)),
        y: (125*(coefficient*coefficientB)),
        width: 700,
        height: 900,
      }
      getImageSettings = screenWidth > 1060 ? [element, false, cropOptions] : [element, true]

    } else if (type === 'chart') {
      element = this.dlChart.nativeElement;
      const downloadModal = document.getElementById('downloadContainer')
      if (downloadModal) {
        downloadModal.scrollTop = 0;
      }         
      cropOptions = {
        x: screenWidth/5,
        y: (screenHeight/3.75)*coefficient,
        width: (1200/(coefficient - 0.25)),
        height: 700,
      }
      getImageSettings = screenWidth > 1060 ? [element, false, cropOptions] : [element, true]   
    }

    // Screenshot
    this.captureService
      .getImage(getImageSettings[0], getImageSettings[1], getImageSettings[2])
      .pipe(
        tap((img) => {
          this.dlLink.nativeElement.href = img;
          this.dlLink.nativeElement.download =
            'export_' + type + '_' + this.chartDetail.chartId + '.png';
          this.dlLink.nativeElement.click();
        })
      )
      .subscribe();
  }

  
  highlightOrganisation(code) {
    // Highlight chart
    // TODO: Improve chart selection (ID?)
    //let dlChart = this.charts.find(c => c.chartWidth === 600);
    let dlChart = this.charts[this.charts.length - 1];

    let dlChartData = dlChart['series'][0]['data'];

    dlChartData.forEach((d) => d.setState(''));
    dlChartData.find((c) => c.category === code).setState('select');

    // Highlight map
    this.createDownloadMap(this.selectedMap, this.chartData, code);

    // Set selected organisation
    this.dlHighlighted = this.tabularData.find(
      (td) => td.organisationCode === code
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  navigateToZsa() {
    window.location.href =
      'https://www.zerosuicidealliance.com/ZSA-Resources/maps-1/suicide-prevention-resource-map';
  }

  onSearchFocus(){
    this.focusSearch = true
  }

  resetSearch(){
    this.search = ''
    this.selectedMenu = { tiers: true, stories: false, downloads: false, any: true };
  }

  setSlug() {
    let slug = this.chartDetail.chartSlug
    if(!this.urlParams.story){
    this.router
      .navigate(['maps', slug, this.chartDetail.dates[0].dateSlug], {queryParamsHandling: 'merge'})
      .then();
    this.urlParams.tier = this.chartDetail.tierId
    }else if(this.urlParams.story){
       this.router
       .navigate(['maps', slug, this.chartDetail.dates[0].dateSlug], {replaceUrl: true, queryParams: { story: this.urlParams.story } })
       .then();
}
    }

  refreshCheck(){
      this.reverseChartSearch = this.flattenedTierArray.find(a => a.chartSlug == this.activatedRoute.snapshot.params.chartSlug)
      if(this.reverseChartSearch){
        this.urlParams.chart = this.reverseChartSearch.chartId
        if (this.urlParams.story) {
          this.getStoryList();
          setTimeout(() => {
            this.getStoryDetail(this.urlParams.story, this.urlParams.chart)
            this.storyMode = true;
          }, 500);
        }
          this.getChartDetail(this.reverseChartSearch.chartId, this.reverseChartSearch.tierId, this.urlParams.story)    
      }
  }

    // Make the DIV element draggable:

    public dragElement(elmnt) {
      this.element = elmnt;
      elmnt.addEventListener('touchstart', (e) => {
        this.dragMouseDown(e);
      });
    }
  
    public dragMouseDown(e) {
      e = e || window.event;
      // get the mouse cursor position at startup:
      this.startY = e.targetTouches[0].clientY;
      if (document.ontouchend) {
        this.closeDragElement();
      }
  
      // call a function whenever the cursor moves:
      this.element.addEventListener('touchmove', (b) => {
        this.elementDrag(b);
      });
    }
  
    public elementDrag(e) {
      let pos2 = 0;
      let width = window.innerWidth;
      let height = window.innerHeight;
      e = e || window.event;
      // calculate the new cursor position:
      this.calcY = this.startY - e.targetTouches[0].clientY;
      this.startY = e.targetTouches[0].clientY;
      this.yPosition = this.element.offsetTop - this.calcY;
      // set the element's new position:
      let elementPerc = (this.yPosition / height) * 100;
      if (elementPerc > 55 && elementPerc < 90) {
        this.element.style.top = elementPerc + '%';
      } else if (elementPerc < 55) {
        this.element.style.top = '55%';
        this.isMobile = false;
      } else if (elementPerc > 90) {
        this.element.style.top = '90%';
      }
    }
  
    public closeDragElement() {
      // stop moving when mouse button is released:
      document.ontouchend = null;
      document.ontouchmove = null;
    }
  
    lineViewFunc() {
      this.isMobile = true;
      this.showChart = true;
      setTimeout(() => {
        this.dragElement(document.getElementById('contentInnerMobile'));
      }, 500);
    }
  
    showToggle() {
      if (this.show == false) {
        this.show = true;
        setTimeout(() => {
          this.dragElement(document.getElementById('contentInnerMobile'));
        }, 200);
      } else if (this.show == true) {
        this.show = false;
      }
    }
    privacyDataCollection() {
      this.googleAnalyticsService.privacyPolicyEvent("Privacy Policy", "click");
    }
} 