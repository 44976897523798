import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }
    public privacyPolicyEvent(eventName: string, action: string = null) {
      gtag('event', eventName, { 
        privacyPolicyEventAction: action,
      })
    }

    public reportEvent(eventName: string, action: string = null, value: string = null) {
      gtag('event', eventName, { 
        reportEventAction: action,
        reportEventValue: value
      })
    }
}
