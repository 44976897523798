import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ZsaService {
  private URL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public TierList(): Observable<any> {
    return this.http.get(this.URL + 'tier/list')
  }

  public FlatTierList(): Observable<any> {
    return this.http.get(this.URL + 'tier/list-flat')
  }

  public ChartDetail(chartId: number): Observable<any> {
    return this.http
      .get(this.URL + 'chart/' + chartId + '/detail');
  }  

  public ChartData(chartId: number, dateId: number, orgTypeId?: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params
    .set('dateId', dateId.toString())
    if (orgTypeId) {
      params = params.append('orgTypeId', orgTypeId.toString());
    }
    return this.http.get(this.URL + 'chart/' + chartId + '/data', {
      params: params,
      });
  }

  public TimeseriesData(chartId: number, orgTypeId: number, orgId?: number): Observable<any> {
    let params: HttpParams = new HttpParams();

    params = params.set('orgTypeId', orgTypeId.toString());

    if (orgId) {
      params = params.append('orgId', orgId.toString());
    };
    
    return this.http.get(this.URL + 'chart/' + chartId + '/timeseries', {
      params: params,
    });
  }

  public Organisations(orgTypeId: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    return this.http.get(this.URL + 'organisation/orgsList', {
      params: params.set('orgTypeId', orgTypeId.toString()),
    });
  }

  public StoryList(): Observable<any> {
    return this.http.get(this.URL + 'story/list')
  }

  public StoryDetail(storyId: number): Observable<any> {
    return this.http
      .get(this.URL + 'story/' + storyId);
  }  
}