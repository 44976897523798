import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetatagsService {

  constructor(
    private titleService: Title,
    private meta: Meta,
  ) { }

  updateMetaTags(metaTitle: string, metaDescription: string) {
    this.titleService.setTitle(metaTitle); // Set page title
    this.meta.updateTag({property: 'og:title', content: metaTitle});
    this.meta.updateTag({property: 'og:description', content: metaDescription});
    this.meta.updateTag({property: 'og:image', content: 'https://www.zerosuicidealliance.com/application/files/2316/2607/8532/ZSA_Main_Logo.svg'});
    this.meta.updateTag({name: 'description', content: metaDescription});
    this.meta.updateTag({name: 'twitter:title', content: metaTitle});
    this.meta.updateTag({name: 'twitter:description', content: metaDescription});
    this.meta.updateTag({name: 'twitter:image', content: 'https://www.zerosuicidealliance.com/application/files/2316/2607/8532/ZSA_Main_Logo.svg'});
  }
}
