import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Org } from '../constants';

@Component({
  selector: 'app-org-select',
  templateUrl: './org-select.component.html',
  styleUrls: ['./org-select.component.scss']
})
export class OrgSelectComponent implements OnChanges {

  @Input() public orgs: Org[];
  @Input() public selectedOrg: Org;
  @Output() orgEmitter: EventEmitter<any> = new EventEmitter();
  
  public searchText: string;
  public showOrgs: boolean = false;

  constructor() { }

  ngOnChanges(): void {}

  public selectOrg(org: Org): void {
    this.selectedOrg = org;
    this.orgEmitter.emit(org);
    this.showOrgs = false;
  }

}
