<div class="card mb-3" [routerLink] = "['/maps', chart.chartDetails?.chartSlug]" queryParamsHandling="merge">
    <div class="card-body">
        <!-- Loading -->
        <div *ngIf="!chartOptions && !error" class="row">
            <div class="col-md-12 text-primary">
                <i class="fas fa-circle-notch fa-spin mr-2"></i>Loading...
            </div>
        </div>
        <!-- Error -->
        <div *ngIf="error" class="row">
            <div class="col-md-12 text-danger">
                <strong>
                    <i class="fas fa-exclamation-circle mr-2"></i>There has been an error loading this data.
                </strong>
                Please try reloading the page.
            </div>
        </div>
        <!-- Success -->
        <div *ngIf="chartOptions && !error" class="row">
            <div class="col-lg-3 col-md-12 col-sm-12">
                <h5>
                    {{ chart.chartDetails?.title }}
                    <span class="text-muted">({{ chart.chartLatestDate?.dateName }})</span>
                </h5>
                <p>
                    <b>{{ org?.organisationTypeName }}:</b> {{ orgData ? (orgData.result | number: '1.0-1') : 'No data' }}
                </p>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12">
                <div [ng2-highcharts]="chartOptions"></div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 pt-2">
                <div class="row">
                    <div class="col-6 text-center">
                        <div>
                            <i class="fa fa-sm fa-diamond" style="color:green"></i><br>
                            {{ org?.organisationTypeId == 3 ? 'LA' : org.organisationTypeName }} value
                        </div>
                        <div>
                            <b>{{ orgData ? (orgData.result | number: '1.0-1') : '&mdash;' }}</b>
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <div>
                            <i class="fa fa-sm fa-circle"></i><br>
                            National median
                        </div>
                        <div>
                            <b>{{ chart.chartAverages?.median | number: '1.0-1' }}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 d-none d-lg-block text-right">
                <i class="fal fa-chevron-right fa-xxl"></i>
            </div>
        </div>
    </div>
</div>

