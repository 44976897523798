import { Component, Input, OnChanges } from '@angular/core';

import { ZsaService } from '../../services/zsa.service';
import { OrderByPipe } from 'ngx-pipes';

@Component({
  selector: 'app-timeseries',
  templateUrl: './timeseries.component.html',
  styleUrls: ['./timeseries.component.scss']
})
export class TimeseriesComponent implements OnChanges {

  @Input() private chartDetail: any; // TODO: Needs model
  @Input() private organisationCode: string;
  @Input() private organisationList: any;

  public chartOptions: any;

  constructor(
    private zsaService: ZsaService,
    public orderBy: OrderByPipe
  ) { }

  ngOnChanges(): void {
    let selectedOrganisation = this.organisationList.find(data => data.code == this.organisationCode)
    this.getTimeseriesData(this.chartDetail, selectedOrganisation ? selectedOrganisation.organisationId : null);
  }

  // TODO: Hook up to new end-point
  private getTimeseriesData(chartDetail: any, organisationId?: number): void {
    this.zsaService.TimeseriesData(chartDetail.chartId, chartDetail.organisationTypeId, organisationId ? organisationId : null).subscribe(
      success => {
        let data = success.data.chartTimeseriesData;
        data = this.orderBy.transform(data, 'dateFrom');
        let categories = data.map(d => d.dateName);
        let nationalData = data.map(d => d.nationalMedian);
        let organisationData = data.map(d => d.organisationValue);
        this.createTimeseriesChart(chartDetail, categories, nationalData, organisationData);
      },
      error => {
        // TODO: Needs error handling
        console.log(error);
      }
    )
  }

  private createTimeseriesChart(chartDetail: any, categories: string[], nationalData: number[], organisationData: number[]): void {
    this.chartOptions = {
      title: { text: '' },
      chart: {
        type: 'line',
        animation: true,
        style: { fontFamily: 'Helvetica, Arial, sans-serif' },
        backgroundColor: 'transparent',
      },
      exporting: {
        enabled: false
      },
      colors: ['#48587F'],
      credits: false,
      legend: {
        align: 'left'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: chartDetail.yAxis
        },
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        series: {
          animation: false
        },
        bar: {
          borderWidth: 0
        }
      },
      series: [
        {
          name: 'National median',
          data: nationalData,
          color: '#009639',
          tooltip: {
            pointFormat: '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}</b><br/>'
          }
        },
        {
          name: 'Organisation value',
          data: organisationData,
          color: '#43537B',
          tooltip: {
            pointFormat: '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}</b><br/>'
          }
        },
      ]
    }
  }

}
