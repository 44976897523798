export interface Tier {
  tierId: number,
  tierName: string;
  childTiers?: Tier[],
  chartId?: number;
  orgTypeId?: number;
  chartLatestDate?: any; // needs model
  chartDetails?: any; // needs model
  chartData?: any; // needs model
  chartAverages?: any; // needs model
}

export interface Org {
    organisationId: number,
    name: string,
    code: string
    organisationTypeId: number,
    organisationTypeName: string,
    country: string;
    orgData?: any; // needs model
}

export interface Key {
  description: string,
  quintiles: Quintile[]
}

export interface Quintile {
  colourName: string,
  colour: string,
  blindColour: string
}

export const Key: Key[] = [
  {
    description: 'Risk-related indicators',
    quintiles: [
        {
            colourName: 'Action needed: lowest 20% quantile ',
            colour: '#FFFF00',
            blindColour: '#88CCEE'
        },
        {
            colourName: 'Urgent action needed: middle 60% quantile',
            colour: '#FFC000',
            blindColour: '#DDCC77'
        },
        {
            colourName: 'Immediate action needed: highest 20% quantile',
            colour: '#FF0000',
            blindColour: '#CC6677'
        },
    ]
  },
  {
    description: 'Performance-related indicators',
    quintiles: [
      {
          colourName: 'Highest 20% quantile',
          colour: '#00B050',
          blindColour: '#117733'
      },
      {
          colourName: '60% quantile',
          colour: '#92D050',
          blindColour: '#44AA99'
      },
      {
          colourName: 'Median ',
          colour: '#FFFF00',
          blindColour: '#88CCEE'
      },
      {
          colourName: '40% quantile',
          colour: '#FFC000',
          blindColour: '#DDCC77'
      },
      {
          colourName: 'Lowest 20% quantile',
          colour: '#FF0000',
          blindColour: '#CC6677'
      }
    ]
  },
  {
    description: 'Service-related indicators',
    quintiles: [
        {
            colourName: 'Light purple: lower number',
            colour: '#E5D1FA',
            blindColour: '#E5D1FA'
        },
        {
            colourName: 'Dark purple: higher number',
            colour: '#19052E',
            blindColour: '#19052E'
        },
    ]
  }
]

export const Tiers: Tier[] = [
    {
        tierId: 1,
        tierName: 'Local authority',
        orgTypeId: 3,
        childTiers: [
            {
                tierName: 'All ages',
                tierId: 2,
                childTiers: [
                    {
                        tierId: 3,
                        tierName: 'Suicide rate: persons',
                        chartId: 114,
                        orgTypeId: 3
                    },
                    {
                        tierId: 4,
                        tierName: 'Suicide rate: male',
                        chartId: 115,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Suicide rate: female',
                        tierId: 5,
                        chartId: 116,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Self harm: hospital admissions ',
                        tierId: 6,
                        chartId: 104,
                        orgTypeId: 3
                    }
                ]
            },
            {
                tierName: 'Children and young people',
                tierId: 7,
                childTiers: [
                    {
                        tierName: 'Number of children in care',
                        tierId: 8,
                        chartId: 55,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Social, emotional, and mental health needs',
                        tierId: 9,
                        chartId: 50,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Number of child protection plans',
                        tierId: 10,
                        chartId: 54,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Permanent and fixed term exclusions from secondary school',
                        tierId: 11,
                        chartId: 61,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Number of hospital admissions in children and young people for mental health disorders',
                        chartId: 99,
                        tierId: 12,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Number of hospital admissions in children and young people as a result of self-harm',
                        tierId: 13,
                        chartId: 67,
                        orgTypeId: 3
                    }
                ]
            },
            {
                tierName: 'Adults',
                tierId: 14,
                childTiers: [
                    {
                        tierName: 'Number of children in care',
                        tierId: 15,
                        chartId: 55,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Number of domestic abuse incidences',
                        tierId: 16,
                        chartId: 125,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Percentage of households experiencing fuel poverty',
                        tierId: 17,
                        chartId: 107,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Average score of life satisfaction',
                        tierId: 18,
                        chartId: 102,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Estimated number of adults dependent on alcohol',
                        tierId: 19,
                        chartId: 80,
                        orgTypeId: 3
                    },
                    {
                        tierName: 'Successful completion of treatment for alcohol',
                        tierId: 20,
                        chartId: 85,
                        orgTypeId: 3
                    }
                ]
            }
        ]
    },
    {
        tierName: 'Sub-ICB',
        tierId: 21,
        orgTypeId: 7,
        childTiers: [
            {
                tierName: 'Children and young people',
                tierId: 22,
                childTiers: [
                    {
                        tierName: 'Number of children living in poverty',
                        tierId: 23,
                        chartId: 51,
                        orgTypeId: 2
                    },
                    {
                        tierName: 'Number of children and young people in contact with mental health services',
                        tierId: 24,
                        chartId: 111,
                        orgTypeId: 7
                    }
                ]
            },
            {
                tierName: 'Adults',
                tierId: 25,
                childTiers: [
                    {
                        tierName: 'Percentage of lower super output areas (LSOAs) in the most deprived 10 percent nationally',
                        tierId: 26,
                        chartId: 10,
                        orgTypeId: 2
                    },
                    {
                        tierName: 'Severe mental illness (SMI) prevalence',
                        tierId: 27,
                        chartId: 3,
                        orgTypeId: 7
                    },
                    {
                        tierName: 'Depression prevalence',
                        tierId: 28,
                        chartId: 96,
                        orgTypeId: 7
                    },
                    {
                        tierName: 'Percentage of completed NHS talking therapies treatment courses that showed reliable improvement',
                        tierId: 30,
                        chartId: 48,
                        orgTypeId: 7
                    }
                ]
            }
        ]
    }
]