import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlossaryService {
  private URL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getGlossaryTermsList(): Observable<any> {
    return this.http.get(this.URL + 'glossary/list')
  }
}