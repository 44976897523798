<!-- Top nav -->
<div class="container-fluid position-relative z-3" style="z-index: 3; box-shadow: 0 4px 2px -2px gray;">

  <!-- Links back to ZSA -->
  <div class="row hideBanner">
    <div class="col-md-12 top-banner-desktop">
      <div class="links d-flex">
        <div class="moveit">
          <a href="https://www.zerosuicidealliance.com/" target="_blank" class="text-decoration-none" style="color: black;">
            <small style="padding-right: 60px;"><i class="fas fa-chevron-left mr-2"></i>Return to Zero Suicide Alliance website</small>
          </a>
        </div>
        <div class="moveit">
          <a href="https://www.nhsbenchmarking.nhs.uk" target="_blank"  class="text-decoration-none" style="color: black;">
            <small><i class="fas fa-chevron-left mr-2"></i>Return to NHS Benchmaking Network website</small>
          </a>
        </div>
      </div>
      <div class="help">
        <div class="urgent moveit">
          <small><a href="https://www.zerosuicidealliance.com/urgent-help" class="text-white text-decoration-none" target="_blank">Urgent Help</a>
          </small>
          <div class="pill"><i class="fas fa-chevron-right position-absolute" style="font-size: 12px; padding-left: 7px; padding-top: 5px; color: orange;"></i></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dashboard Nav-->
  <div class="row">
    <div class="col-md-3 top-banner-blue">
      <h5>Explore suicide related data</h5>
      <p>Data is available in dashboard, graph, map and table format.</p>
      <div class="d-flex w-100 align-items-start">
        <div class="nav-tab active" [routerLink]="'/dashboard'" queryParamsHandling="merge">
          Dashboard
        </div>
        <div class="nav-tab" [routerLink]="'/maps'" queryParamsHandling="merge">
          Data Map
        </div>
      </div>
    </div>
    <div class="col-md-9 top-selections">
      <div class="d-flex align-items-end">
        <div class="guidance">
          Compare suicide related data to other local authorities or sub-integrated care boards (formerly CCGs) across England.
        </div>
      </div>
      <div class="form-group mr-2 mb-2 d-flex flex-row justify-content-end">
        <img src="https://www.zerosuicidealliance.com/application/files/2316/2607/8532/ZSA_Main_Logo.svg" alt="Zero Suicide Alliance logo" height="70" style="padding-right: 15px;">
        <img src="../../assets/nhsbn-mark-new.png" alt="NHSBN logo" height="70">
      </div>
    </div>
  </div>
</div>

<!-- Dashboard -->
<div class="container-fluid">
    <div class="row">
      <!-- Sidebar -->
      <div id="sidebar" class="col-lg-3 col-md-4 col-sm-12 py-3 px-5">
          <!-- Organisation selector -->
          <app-org-select [orgs]="orgs" [selectedOrg]="selectedOrg" (orgEmitter)="selectOrg($event)"></app-org-select>
          <ng-container *ngIf="!selectedTier">
            <div class="alert alert-warning">
              <i class="fas fa-arrow-up mr-2"></i>
              Start by selecting a local authority or sub-ICB area from the drop-down.
            </div>
          </ng-container>
          <!-- Available tiers -->
          <div *ngIf="availableTier" class="mt-4">
              <div *ngFor="let tier of availableTier.childTiers" (click)="selectTier(tier)">
                  <h5 class="my-3 hover-pointer" [style.color]="selectedTier?.tierName == tier.tierName  ? 'black' : '#4D5E83'">
                      {{ tier.tierName }}
                  </h5>
              </div>
          </div>
          <!-- Key -->
          <div id="keyContainer">
            <div class="key" ng-container *ngIf="!hideKey">
              <div *ngFor="let key of keys">
                <div class="font-weight-bold mt-2">{{ key.description }}</div>
                <div *ngFor="let item of key.quintiles" class="mr-2">
                  <i class="fas fa-square mr-2" [style.color]="colourBlind ? item.blindColour : item.colour"></i><span [innerHTML]="item.colourName"></span>
                </div>
              </div>
              <div class="mt-2 mb-1">
                <i class="fas fa-info-circle mr-1"></i>Find out more on the Zero Suicide Alliance <a href="https://www.zerosuicidealliance.com/suicide-data-map#single-accordion-20941" target="_blank">map page</a>.
              </div>
            </div>
            <button (click)="hideKey = !hideKey" class="btn btn-primary mt-3">
              <i class="fas mr-2" [class.fa-caret-up]="hideKey" [class.fa-caret-down]="!hideKey"></i>
              <span *ngIf="hideKey">Show key</span>
              <span *ngIf="!hideKey">Hide key</span>
            </button>
          </div>
      </div>
      <!-- Charts -->
      <div class="col-lg-9 col-md-8 py-4 px-4" *ngIf="selectedTier">
        <!-- Selected org -->
        <div *ngIf="selectedOrg" class="mb-4">
          <i class="far fa-map-marker mr-2"></i>
          <strong>{{ selectedOrg.name }}</strong>
          <i class="fas fa-times ml-2 cursor-pointer" (click)="clearSelectedOrg()"></i>
        </div>
        <div *ngFor="let childTier of selectedTier.childTiers">
            <app-chart [chart]="childTier" [org]="selectedOrg" [colourBlind]="colourBlind"></app-chart>
        </div>
      </div>
      <!-- No charts -->
      <div class="col-lg-7 offset-md-1 col-md-6 px-5 mt-5 text-primary" *ngIf="!selectedTier">
        <h2 style="margin-bottom:1em">Suicide data dashboards</h2>
        <p>
          Understanding suicide risk and related data can help you know where to take action.
        </p>
        <p>
          Our suicide data dashboard enables you to look at a selected number of suicide risk factors by local authority or sub-ICB area and compares this against the national average.
        </p>
        <p>
          Each indicator included in the dashboard also acts as a quick link so you can also view the data within the full suicide data map. You can explore more than 100 indicators for suicide risk in the full suicide data map.
        </p>
        <p class="font-weight-bold">
          Start by selecting your local authority or sub-ICB area from the drop-down menu.
        </p>
      </div>
    </div>
</div>

<!-- Accessibility -->
<div id="footer" (click)="toggleColourBlind()">
  <span class="colourblindness">
    <strong>Accessibility Tool</strong>: Turn colourblind mode on
  </span>
</div>