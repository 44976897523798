import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Tier, Tiers, Org, Key } from './constants';
import { ZsaService } from '../services/zsa.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  
})
export class DashboardComponent implements OnInit {

  public tiers: Tier[] = Tiers;
  public orgs: Org[] = [];
  public keys: Key[] = Key;
  public selectedOrg: Org;
  public availableTier: Tier;
  public selectedTier: Tier;

  public hideKey: boolean = true;
  public colourBlind: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private zsaService: ZsaService,
    public router: Router
  ) { }

  ngOnInit(): void {
    let params = this.route.snapshot.queryParams;
    this.getOrganisations(params);
  }

  private getOrganisations(params: any): void {
    // 3 = LA, 7 = Sub-ICB
    [3, 7].forEach((id, i) => {
      this.zsaService.Organisations(id).subscribe(
        success => {
          success.data.orgTypesData.forEach(org => this.orgs.push(org));
          if (i == 1) {
            this.selectOrgAndTier(params);
          }
        },
        error => {
          console.log(error);
        },
      )
    });
  }

  public selectOrg(org: Org): void {
    this.selectedOrg = org;
    this.availableTier = this.tiers.find(tier => tier.orgTypeId == org.organisationTypeId);
    // Default to top tier
    let defaultTier = this.availableTier.childTiers[0];
    this.selectTier(defaultTier);
    this.router.navigate([], { queryParams: { org: org.code, tier: defaultTier.tierId }, queryParamsHandling: 'merge' });
  }

  public selectTier(tier: Tier): void {
    this.selectedTier = tier;
    this.router.navigate([], { queryParams: { tier: tier.tierId }, queryParamsHandling: 'merge' });
  }

  // TODO: Needs model for params
  private selectOrgAndTier(params: any): void {
    let org = this.orgs.find(o => o.code == params.org);
    if (org) {
      this.selectedOrg = org;
      this.selectOrg(org);
    }
  }

  public clearSelectedOrg(): void {
    this.selectedOrg = null;
    this.availableTier = null;
    this.selectedTier = null;
    this.router.navigate([], { queryParams: { org: null, tier: null }, queryParamsHandling: 'merge' });
  }

  public toggleColourBlind(): void {
    this.colourBlind = !this.colourBlind;
  }

}