import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { filter } from 'rxjs/operators';

declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public cookieBannerVisible: boolean = true;
  public navbarCollapsed: boolean = true;

  
  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    ) { 

    }

  ngOnInit(): void {
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-L0X8VJY00T', {
        'page-path': event.urlAfterRedirects,
      });
    })
    this.checkCookieExpirationDate();
  }
  

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }

  setCookieExpirationDate() {
    // set cookie with key and value to expire 30 days from now
    var expires = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
    document.cookie = [
        "key=value",
        "expires=" + expires.toUTCString()
    ].join("; ");
    // store cookie expiration date for key in local storage
    if (window.localStorage) {
        localStorage.setItem("keyExpiresZSA", expires.toISOString());
    }
  }

  allowCookies() {
    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
    });
    gtag('config', 'G-L0X8VJY00T');

    this.cookieBannerVisible = false;
    this.setCookieExpirationDate();
  }

  checkCookieExpirationDate() {
    var expires = localStorage.getItem("keyExpiresZSA");

    var date = new Date();
    var n = date.toISOString();

    if(n > expires || expires == null ) {
      this.cookieBannerVisible = true;
    }
    else {
      this.cookieBannerVisible = false;
      this.allowCookies(); 
    }
  }

  privacyDataCollection() {
    this.googleAnalyticsService.privacyPolicyEvent("Privacy Policy", "click");
    this.router.navigate(['/privacy-policy']);
  }

}
