<!-- Errors -->
<div class="error" *ngIf="error" (click)="error = null">
  {{ error }}. Click to dismiss.
</div>

<!-- Mobile View -->
<div  id="contentOuter" *ngIf="show && isMobile">

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div id="contentInnerMobile" *ngIf="isMobile" >

          <!-- Menu Closer -->
          <div id="menuClose" *ngIf="selectedMenu['any']" (click)="setMenu('any')" aria-label="Close menu"></div>
          <div (click)="isMobile = false" id="line" class="line"></div>
          <!-- <button (click)="test()">hello</button> -->

          <!-- Chart -->
          <div id="container-chart" class="container-chart" *ngIf="showChart">
            <div id="stories" *ngIf="storyMode" class="bg-primary rounded mb-3 p-3">
              <div class="row">
                <div class="col-5">
                  <button class="btn btn-sm btn-outline-light mr-1" [disabled]="counter == minCounter" (click)="changeIndicator(-1)">Previous</button>
                  <button class="btn btn-sm btn-outline-light" [disabled]="counter == maxCounter" (click)="changeIndicator(1)">Next</button>
                </div>
          
              </div>
              <div class="row mt-4">  
                <div class="col-12">
          
                  <div class="mt-1">
                    <span 
                      class="text-white cursor-pointer" 
                      [innerHTML]="storyInfo.length > 150 && textOpen ? (storyInfo | slice: 0:textCounter) + '... [+]' : storyInfo + ' [-]'"
                      (click)="toggleStoryIntro()">
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!storyMode">
              <div class="arrow-container">
                <button (click)="traverseArray('prev')" title="Previous indicator"  class="arrow"><i class="far fa-chevron-left fa-2x"></i></button>
                <h2 class="text-primary text-center">{{ chartDetail.title }}</h2>
                <button style="left: 17px;" title="Next indicator" (click)="traverseArray('next')" class="arrow position-relative"><i class="far fa-chevron-right fa-2x"></i></button>
            </div>

            </ng-container>
            <ng-container *ngIf="storyMode">
              <h2 class="text-primary mb-3 pr-1"><strong>Indicator {{ counter }}:</strong> {{ chartDetail.title }}</h2>
              <h5 class="text-secondary mb-3" [innerHTML]="storyParts[counter - 1].narrative | safe: 'html'"></h5>
            </ng-container>

            <div class="chart" [ng2-highcharts]="chartOptions"></div>

            <div class="form-inline mb-1">            

            </div>
            <!-- Table -->
            <div *ngIf="showTabular" class="info-container">
              <h4 class="mb-2">Data Table</h4>
              <table class="table table-sm table-bordered bg-white">
                <tr>
                  <th style="width:25%">Code</th>
                  <th style="width:50%">Name</th>
                  <th style="width:25%">Value</th>
                </tr>
                <tr *ngFor="let a of tabularData">
                  <td>{{a.organisationCode}}</td>
                  <td>{{a.organisationName}}</td>
                  <td>{{a.value | number: dataDecimal}}<span *ngIf="chartDetail.format == 'P'">%</span></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid hideMobileBanner position-relative z-3" *ngIf="!isMobile" style="z-index: 3; box-shadow: 0 4px 2px -2px gray;">
  <div class="row">
    <div class="col-md-12 top-banner-desktop">
      <div class="links d-flex">
        <div class="moveit">
          <a href="https://www.zerosuicidealliance.com/" target="_blank" class="text-decoration-none" style="color: black;">
            <small style="padding-right: 60px;"><i class="fas fa-chevron-left mr-2"></i>Return to Zero Suicide Alliance website</small>
          </a>
        </div>
        <div class="moveit">
          <a href="https://www.nhsbenchmarking.nhs.uk" target="_blank"  class="text-decoration-none" style="color: black;">
            <small><i class="fas fa-chevron-left mr-2"></i>Return to NHS Benchmaking Network website</small>
          </a>
        </div>
      </div>
      <div class="help">
        <div class="urgent moveit">
          <small><a href="https://www.zerosuicidealliance.com/urgent-help" class="text-white text-decoration-none" target="_blank">Urgent Help</a>
          </small>
          <div class="pill"><i class="fas fa-chevron-right position-absolute" style="font-size: 12px; padding-left: 7px; padding-top: 5px; color: orange;"></i></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!isMobile">
    <div class="col-md-3 top-banner-blue">
      <h5>Explore suicide related data</h5>
      <p>Data is available in dashboard, graph, map and table format.</p>
      <div class="d-flex w-100 align-items-start">
        <div class="nav-tab" [routerLink]="'/dashboard'" queryParamsHandling="merge">
          Dashboard
        </div>
        <div class="nav-tab active" [routerLink]="'/maps'" queryParamsHandling="merge">
          Data Map
        </div>
      </div>
    </div>
    <div class="col-md-9 top-selections">
      <div class="d-flex align-items-end">
        <div class="form-group mr-2 mb-2 text-primary" style="width: 200px;">
          <strong>Category</strong>
          <select class="form-control" (change)="selectedCategoryFilter($event.target.value)" [ngModel]="selectedCategory">
            <option value="null" disabled>Select a category...</option>
            <option *ngFor="let m of tiers" value="{{ m.tierId }}" [innerHTML]="m.title + selectOptionExtraText"></option>
          </select>
        </div>
        <div class="form-group mr-2 mb-2 text-primary" style="width: 200px;">
          <strong>Grouping</strong>
          <select [disabled]="!selectedCategory" class="form-control" (change)="selectedGroupingFilter($event.target.value)" [ngModel]="selectedGrouping">
            <option value="null" disabled>{{ selectedCategory !== null ? 'Select a grouping...' : 'Waiting for a category'}}</option>
            <option *ngFor="let m2 of grouping[0]?.children" value="{{ m2.tierId }}" [innerHTML]="m2.title + selectOptionExtraText"></option>
          </select>
        </div>
        <div class="form-group mr-2 mb-2 text-primary" style="width: 200px;">
          <strong>Indicator</strong>
          <select [disabled]="!selectedGrouping" class="form-control" (change)="selectedIndicatorFilter($event.target.value, selectedIndicator)" [ngModel]="selectedIndicator">
            <option value="null" disabled>{{ selectedGrouping !== null ? 'Select an indicator...' : 'Waiting for a grouping'}}</option>
            <option *ngFor="let m of indicator[0]?.children" value="{{ m.tierId }}" [innerHTML]="m.title + selectOptionExtraText"></option>
          </select>
        </div>
        <div class="form-group mr-2 mb-2 d-flex flex-column justify-content-end">
          <button class="btn btn-outline-primary" (click)="showChart = false; reset()">RESET</button>
        </div>
      </div>
      <div class="form-group mr-2 mb-2 d-flex flex-row justify-content-end">
        <img src="https://www.zerosuicidealliance.com/application/files/2316/2607/8532/ZSA_Main_Logo.svg" alt="Zero Suicide Alliance logo" height="70" style="padding-right: 15px;">
        <img src="../../assets/nhsbn-mark-new.png" alt="NHSBN logo" height="70">
      </div>
    </div>
  </div>
</div>

<!-- Menu and Charts -->
<div id="contentOuter" *ngIf="show && !isMobile">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div id="contentInner" *ngIf="!isMobile">
          <div *ngIf="lineView" (click)="lineViewFunc()" id="lines" class="line"> <strong><i class="far fa-hand-pointer mr-1 pt-2"></i>Tap to minimize window</strong></div>
          <div class="col-md-12 hideBanner top-banner-mobile" style="display: none;">
            <div class="links">
              <a href="https://www.zerosuicidealliance.com/ZSA-Resources/suicide-prevention-resource-map"
                target="_blank" style="color: black"><small style="padding-right: 60px;">
                  <i class="fas fa-chevron-left mr-2"></i>Return to Zero Suicide Alliance website</small></a>
              <a href="https://www.nhsbenchmarking.nhs.uk" target="_blank" style="color: black"><small>
                <i class="fas fa-chevron-left mr-2"></i>Return to NHS Benchmaking Network website</small></a>
            </div>
            <div class="help">
              <div class="urgent">
                <small><a href="https://www.zerosuicidealliance.com/urgent-help" target="_blank" class="text-white text-decoration-none">Urgent Help</a>
                </small>
                <div class="pill"><i class="fas fa-chevron-right position-absolute text-white" style="padding-left: 5px; padding-top: 4px;"></i></div>
              </div>
            </div>
          </div>
          <div class="col-md-12 top-banner-blue hideBanner">
            <h5>Explore suicide related data</h5>
            <p>Data is available in dashboard, graph, map and table format.</p>
            <div class="d-flex w-100 align-items-start">
              <div class="nav-tab" [routerLink]="'/dashboard'" queryParamsHandling="merge">
                Dashboard
              </div>
              <div class="nav-tab active" [routerLink]="'/maps'" queryParamsHandling="merge">
                Data Map
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center hideBanner">
            <div class="col-6 top-banner" *ngIf="showChart" style="background-color: #8A98BC;">
              <small style="font-size: 12px;" (click)="showChart = false; reset()"> 
                Close indicator
              </small>
            </div>
          </div>

          <div id="contentInnerDektop">
            <!-- Menu Closer -->
            <div id="menuClose" *ngIf="selectedMenu['any']" (click)="setMenu('any')" aria-label="Close menu"></div>
            <!-- Menu -->
            <div style="margin-bottom: -20px;" class="menu" *ngIf="!storyMode">
              <div *ngIf="searchableTiers" class="form-group tiers-container">
                <div class="hideBanner" *ngIf="!isMobile">
                  <div class="col-md-12 mt-4 top-banner" *ngIf="!showChart" style="background-color: #8A98BC;"
                    (click)="displayMenu == true ? displayMenu = false : displayMenu = true">
                    <small style="font-size: 12px;"> 
                      {{ displayMenu ? 'Close' : 'Select indicator'}}
                    </small>
                  </div>
                  <div class="col-md-12" *ngIf="displayMenu" style="padding: 20px;">
                    <div class="dropdown-mobile" style="display: contents;">
                      <div class="form-group mr-2 mb-2 text-primary">
                        <strong>Category</strong>
                        <select class="form-control" (change)="selectedCategoryFilter($event.target.value)" [ngModel]="selectedCategory">
                          <option value="null" disabled>Select a category...</option>
                          <option *ngFor="let m of tiers" value="{{ m.tierId }}">{{ m.title }}</option>
                        </select>
                      </div>
                      <div class="form-group mr-2 mb-2 text-primary">
                        <strong>Grouping</strong>
                        <select [disabled]="!selectedCategory" class="form-control" (change)="selectedGroupingFilter($event.target.value)" [ngModel]="selectedGrouping">
                          <option value="null" disabled>{{ selectedCategory !== null ? 'Select a grouping...' : 'Waiting for a category'}}</option>
                          <option *ngFor="let m2 of grouping[0]?.children" value="{{ m2.tierId }}">{{ m2.title }}</option>
                        </select>
                      </div>
                      <div class="form-group mr-2 mb-2 text-primary">
                        <strong>Indicator</strong>
                        <select [disabled]="!selectedGrouping" class="form-control" (change)="selectedIndicatorFilter($event.target.value, selectedIndicator)" [ngModel]="selectedIndicator">
                          <option value="null" disabled>{{ selectedGrouping !== null ? 'Select an indicator...' : 'Waiting for a grouping'}}</option>
                          <option *ngFor="let m of indicator[0]?.children" value="{{ m.tierId }}">{{ m.title }}</option>
                        </select>
                      </div>
                      <div class="form-group mr-2 mb-2 d-flex flex-column justify-content-end">
                        <button class="btn btn-outline-primary" (click)="showChart = false; reset()">RESET</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Chart -->
            <div class="container-chart mt-5" *ngIf="showChart">
              <div id="stories" *ngIf="storyMode" class="bg-primary rounded mb-3 p-3">
                <div class="row">
                  <div class="col-5">
                    <button class="btn btn-sm btn-outline-light mr-1" [disabled]="counter == minCounter" (click)="changeIndicator(-1)">
                      Previous
                    </button>
                    <button class="btn btn-sm btn-outline-light" [disabled]="counter == maxCounter" (click)="changeIndicator(1)">
                      Next
                    </button>
                  </div>
                  <div class="col-7 text-right">
                    <div class="text-light pt-1 mr-3">Indicator {{ counter }} of {{ maxCounter }}</div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="font-weight-bold text-white">
                      {{ storyTitle }}
                    </div>
                    <div class="mt-1">
                      <span class="text-white cursor-pointer"
                        [innerHTML]="storyInfo.length > 150 && textOpen ? (storyInfo | slice: 0:textCounter) + '... [+]' : storyInfo + ' [-]'"
                        (click)="toggleStoryIntro()">
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="!storyMode">
                <div *ngIf="showCodeBlock" class="arrow-container desktop-arrow">
                  <button style="right: 40px;" title="Previous indicator" class="arrow" (click)="traverseArray('prev')">
                    <i class="far fa-chevron-left fa-2x"></i>
                  </button>
                  <button style="left: 50px;" title="Next indicator" class="arrow" (click)="traverseArray('next')">
                    <i class="far fa-chevron-right fa-2x"></i>
                  </button>
                </div>
                <div class="chartTitle">
                  <h3 class="text-primary mb-3">{{ chartDetail.title }}</h3>
                </div>
                <h5 class="text-secondary mb-1" *ngIf="chartDetail.narrative" [innerHTML]="chartDetail.narrative | safe: 'html'"></h5>
              </ng-container>
              <ng-container *ngIf="storyMode && storyParts">
                <h2 class="text-primary mb-3 pr-1">
                  <strong>Indicator {{ counter }}:</strong> {{ chartDetail.title }}
                </h2>
                <h5 class="text-secondary mb-3" [innerHTML]="storyParts[counter - 1]?.narrative | safe: 'html'"></h5>
              </ng-container>

              <!-- Sub-nav -->
              <ul id="chartNav" class="nav nav-tabs mt-3 mb-2">
                <li class="nav-item">
                  <div class="nav-link" [class.active]="visibleChart == 'column'" (click)="visibleChart = 'column'">Latest Data</div>
                </li>
                <li class="nav-item">
                  <div class="nav-link" [class.active]="visibleChart == 'timeseries'" (click)="visibleChart = 'timeseries'">Time Series</div>
                </li>
              </ul>

              <!-- Time series -->
              <ng-container *ngIf="visibleChart == 'timeseries'">
                <app-timeseries [chartDetail]="chartDetail" [organisationCode]="selectedOrganisationCode" [organisationList]="organisationList"></app-timeseries>
              </ng-container>
              
              <ng-container *ngIf="visibleChart == 'column'">
                <!-- Column chart -->
                <div class="chart" [ng2-highcharts]="chartOptions"></div>
                <!-- Quintiles -->
                <div class="chart-specific-data">
                  <div class="data" *ngIf="showAdvancedData">
                    <div [style.background-color]="showColourBlind && apiColourBlindAlternative == 'Y' ? apiColours[4].blindColour : apiColours[4].colour"></div>
                    <div>Quintile 80%: {{ averages.quintile_80 | number: dataDecimal }}
                      <span *ngIf="chartDetail.format == 'P'">%</span>
                    </div>
                  </div>
                  <div class="data" *ngIf="showAdvancedData">
                    <div [style.background-color]="showColourBlind && apiColourBlindAlternative == 'Y' ? apiColours[3].blindColour : apiColours[3].colour"></div>
                    <div>Quintile 60%: {{ averages.quintile_60 | number: dataDecimal }}
                      <span *ngIf="chartDetail.format == 'P'">%</span>
                    </div>
                  </div>
                  <div class="data" *ngIf="showAdvancedData">
                    <div [style.background-color]="showColourBlind && apiColourBlindAlternative == 'Y' ? apiColours[2].blindColour : apiColours[2].colour"></div>
                    <div>Median: {{ averages.median | number: dataDecimal }}
                      <span *ngIf="chartDetail.format == 'P'">%</span>
                    </div>
                  </div>
                  <div class="data" *ngIf="!showAdvancedData">
                    <div [style.background-color]="'#009639'"></div>
                    <div>Median: {{ averages.median | number: dataDecimal }}
                      <span *ngIf="chartDetail.format == 'P'">%</span>
                    </div>
                  </div>
                  <div class="data" *ngIf="showAdvancedData">
                    <div [style.background-color]="showColourBlind && apiColourBlindAlternative == 'Y' ? apiColours[1].blindColour : apiColours[1].colour"></div>
                    <div>Quintile 40%: {{ averages.quintile_40 | number: dataDecimal }}
                      <span *ngIf="chartDetail.format == 'P'">%</span>
                    </div>
                  </div>
                  <div class="data" *ngIf="showAdvancedData">
                    <div [style.background-color]="showColourBlind && apiColourBlindAlternative == 'Y' ? apiColours[0].blindColour : apiColours[0].colour"></div>
                    <div>Quintile 20%: {{ averages.quintile_20 | number: dataDecimal }}
                      <span *ngIf="chartDetail.format == 'P'">%</span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Source -->
              <div class="chart-specific-data text-primary data-source">
                <strong>Data source:&nbsp;</strong>
                <span [innerHtml]="chartDetail.dataSource"></span>
                <strong>Last update:&nbsp;</strong>
                <span *ngIf="chartDetail.lastUpdate">{{ chartDetail.lastUpdate | date: 'MMM y' }}</span>
                <span *ngIf="!chartDetail.lastUpdate && chartDetail.discontinued == 'Y'">Data no longer collected</span>
                <span class="px-2">|</span>
                <span *ngIf="chartDetail.nextUpdate">
                  <strong>Estimated next update:&nbsp;</strong>
                  <span>{{ chartDetail.nextUpdate | date: 'MMM y' }}</span>
                </span>
                <span *ngIf="!chartDetail.nextUpdate">
                  <strong>Next update:&nbsp;</strong>
                  <span>TBC</span>
                </span>
              </div>

              <h5>Additional options</h5>
              <div class="d-flex mb-1" style="font-size: 13px;">
                <!-- Select: Period -->
                <div class="form-group mr-2 mb-2 text-primary" style="width: 200px;" *ngIf="dates">
                  <strong>Filter by date</strong>
                  <select class="form-control" [disabled]="dates.length == 1 || visibleChart == 'timeseries'" (change)="dateChange($event.target.value)" [ngModel]="selectedDate">
                    <option *ngFor="let d of dates" [value]="d.dateId">{{d.dateName}}</option>
                  </select>
                </div>
                <!-- Select: Map type-->
                <div class="form-group mr-2 mb-2 text-primary" style="width: 200px;" *ngIf="maps">
                  <strong>Filter by area</strong> 
                    <i class="fas fa-info-circle ml-1" style="cursor: pointer"
                      title="Area boundaries may change by indicator. The map shows the geographical footprint of the organisations that report the data. A chequered area shows where data is unavailable for a particular indicator.">
                    </i>
                  <select class="form-control" [disabled]="mapTypes.length == 1" (change)="mapChange($event.target.value)" [ngModel]="selectedMapType">
                    <option *ngFor="let m of mapTypes" value="{{m}}">{{m}}</option>
                  </select>
                </div>
                <!-- Select: Organisation highlight -->
                <div class="form-group mr-2 mb-2 text-primary" style="width: 200px;" *ngIf="dates">
                  <strong>Highlight an organisation</strong>
                  <select class="form-control" [disabled]="organisationList.length == 1" (change)="organisationChange($event.target.value)" [(ngModel)]="selectedOrganisationCode">
                    <option disabled [value]="null">Select an organisation...</option>
                    <option *ngFor="let org of organisationList | orderBy: 'name'" [value]="org.code">{{ org.name }}</option>
                  </select>
                </div>
              </div>

              <div>
                <div class="d-flex mobile-button">
                  <div class="table-option moveit" (click)="showTabular = !showTabular">
                    <small>{{ showTabular ? 'Hide' : 'Show' }} data table 
                    </small>
                    <div class="pill-orange"><i class="fas fa-table" style="color: #48587F; padding-left: 6px; font-size: 13px;"></i></div>
                  </div>
                  <div class="table-option moveit" (click)="setMenu('downloads'); createDownloadMap(selectedMap, chartData)">
                    <small>Download options 
                    </small>
                    <div class="pill-orange"><i class="fas fa-download" style="color: #48587F; padding-left: 6px; font-size: 13px;"></i></div>
                  </div>
                  <div class="table-option moveit" (click)="copyClipboard(copyUrl); copied = true">
                    <small>{{ copied ? 'Copied' : 'Copy link' }} 
                    </small>
                    <div class="pill-orange"><i class="fas fa-link" style="color: #48587F; padding-left: 6px; font-size: 14px;"></i></div>
                  </div>
                </div>
                <input type="text" [value]="'https://www.zsabenchmarking.co.uk' + router.url" id="copyClipboard" spellcheck="false" #copyUrl>
              </div>

              <!-- Info -->
              <div *ngIf="showInfo" class="info-container">
                <h4 class="mb-2">Report Information</h4>
                <p *ngIf="chartDetail.description">
                  <strong>Description: </strong><span [innerHtml]="chartDetail.description"></span>
                </p>
                <p *ngIf="chartDetail.dataSource">
                  <strong>Data source: </strong><span [innerHtml]="chartDetail.dataSource"></span>
                </p>
              </div>

              <!-- Table -->
              <div *ngIf="showTabular" class="info-container">
                <h4 class="mb-2">Data Table</h4>
                <table class="table table-sm table-bordered bg-white">
                  <tr>
                    <th style="width:25%">Code</th>
                    <th style="width:50%">Name</th>
                    <th style="width:25%">Value</th>
                  </tr>
                  <tr *ngFor="let a of tabularData">
                    <td>{{a.organisationCode}}</td>
                    <td>{{a.organisationName}}</td>
                    <td>{{a.value | number: dataDecimal}}<span *ngIf="chartDetail.format == 'P'">%</span></td>
                  </tr>
                </table>
              </div>

              <!-- Missing Areas -->
              <div *ngIf="showMissingAreas" class="info-container">
                <h4 class="mb-2">Missing Areas Table</h4>
                <table class="table table-sm table-bordered bg-white">
                  <tr>
                    <th style="width:25%">Code</th>
                    <th>Name</th>
                  </tr>
                  <tr *ngFor="let a of missingAreas">
                    <td>{{a.code}}</td>
                    <td>{{a.name}}</td>
                  </tr>
                </table>
              </div>
            </div>

            <!-- Intro -->
            <div style="margin-top: 50px" *ngIf="!slug && !showChart">
              <div class="d-flex">
                <h2 class="text-primary mb-3">Suicide data map</h2>
              </div>
              <h5 class="text-secondary mb-3">Developed by the Zero Suicide Alliance and the NHS Benchmarking Network</h5>
              <p>
                Our interactive map is an intelligence tool which shares data on suicide, suicide risk factors, and mental health and wellbeing in England.<br>
                The map pulls data together from multiple sources, including over 100 indicators related to: 
              </p>              
              <ul>
                <li>suicide incidence</li>
                <li>health and social risk factors</li>
                <li>at risk populations</li>
                <li>service demand and provision</li>
              </ul>
              <p>
                The map can be used by anyone who is interested in developing their understanding of suicide and its risk factors. This can support planning, benchmarking and monitoring of suicide prevention initiatives.
              </p>
              <p>
                Use the filters at the top of the page to begin exploring the data. 
              </p>
              <p>
                To see how the data changes over time, select the time series tab in the map chart.
              </p>     
            </div>

            <!-- Loading -->
            <div *ngIf="urlParams.chart && !showChart && !isMobile" class="text-primary font-weight-bold text-center mt-5">
              <i class="fas fa-circle-notch fa-spin mr-2"></i>Loading chart...
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="apiColourBlindAlternative == 'Y' && showAdvancedData" id="footer" (click)="toggleColourBlind()">
      <span class="colourblindness" [ngStyle]="showColourBlind ? {'border': '1px solid #48587F'} : ''">
        <strong>Accessibility Tool</strong>: Turn colourblind mode on
      </span>
    </div>
  </div>

</div>

<!-- Map -->
<div class="map" *ngIf="mapboxOptions">
  <mgl-map
    [style]="mapboxOptions.style" 
    [zoom]="mapboxOptions.zoom" 
    [center]="mapboxOptions.center"
    [logoPosition]="mapboxOptions.logoPosition"
    (load)="mapboxMap = $event; urlParams.chart ? getChartDetail(urlParams.chart, urlParams.tier, urlParams.story, true) : null"
    (sourceDataLoading)="loadChart($event)"
    (mouseMove)="mapboxMap ? mbMouseOverMap($event) : ''"
    (zoomEnd)="checkZoom($event.target)"
    (dragEnd)="checkMove($event.target)">
    <mgl-layer
      id="organisation_areas"
      [type]="mapboxOptions.type"
      [source]="mapboxOptions.source"
      [paint]="mapboxOptions.paint"
      [filter]="['has', 'value']"
      (mouseMove)="mbMouseOver($event)"
      (mouseLeave)="mbMouseOut()">
    </mgl-layer>
    <mgl-layer
      id="organisation_areas_hover"
      type="line"
      [source]="mapboxOptions.source"
      [paint]="mapboxOptions.hover"
      [filter]="mbHoverFilter">
    </mgl-layer>
    <mgl-image id="hash_bg" 
      url="/assets/hatch_xs.png">
    </mgl-image>
    <mgl-layer
      id="organisation_areas_missing"
      [type]="mapboxMissingOptions.type"
      [source]="mapboxMissingOptions.source"
      [paint]="mapboxMissingOptions.paint">
    </mgl-layer>
    <mgl-control mglNavigation></mgl-control>
  </mgl-map>
</div>

<!-- Map Legend -->
<div class="map-legend-container" *ngIf="showLegend && showAdvancedData">
  <div class="d-flex flex-nowrap justify-content-center">
    <div class="help-question">
      <i class="fas fa-question-circle text-secondary" (click)="showHelp = !showHelp"></i>
    </div>
  </div>
  <div class="d-flex flex-nowrap justify-content-end" *ngFor="let c of apiColours.slice().reverse()">
    <div class="map-legend-value" *ngIf="c.value || c.value == 0">{{c.value | number: dataDecimal}}<span *ngIf="chartDetail.format == 'P'">%</span></div>
    <div class="px-2"><div class="map-legend-colour" [style.background-color]="showColourBlind && apiColourBlindAlternative == 'Y' ? c.blindColour : c.colour"></div></div>
    <div><i class="fas fa-caret-left text-secondary"></i></div>
  </div>
</div>

<!-- Help -->
<div id="help" *ngIf="showHelp">
  <p>
    The legend shows the minimum and maximum value from the data set, and the colours denote the quintiles. Hover over an area to view the area's response, highlighted on the map and chart.
  </p>
  <button class="btn btn-sm btn-outline-light" (click)="showHelp = false">Close</button>
</div>

<!-- Hover Selection -->
<div class="selected-data" *ngIf="selectedData">
  <strong>{{ selectedData.organisationName }}</strong><br>
  <span>{{ selectedData.submissionResult | number: dataDecimal }}</span><span *ngIf="chartDetail.format == 'P'">%</span>
</div>

<!-- Copyright -->
<div class="copyright" *ngIf="selectedMap && (selectedMap.name === 'trust' || selectedMap.name === 'camhs')">
  Map polygons &copy; <a href="https://www.nhsbenchmarking.nhs.uk" target="_blank">NHS Benchmaking Network</a> 2020
</div>

<!-- Downloads -->
<div id="downloadContainer" *ngIf="selectedMenu.downloads">

  <div class="closer m-2" (click)="setMenu('any')" aria-label="Close">&times;</div>
  
  <div class="intro">
    <h3>Download Options</h3>
    <p>
      Click to download the chart or map below, or use the drop-down menu to select an organisation to highlight on the chart or map prior to download. You can also zoom in or out on the map to highlight a particular area.
    </p>
  </div>

  <!-- Organisation Selector -->
  <div class="form-group">
    <select class="form-control" (change)="highlightOrganisation($event.target.value)">
      <option selected disabled>Select an organisation...</option>
      <option *ngFor="let data of tabularData | orderBy: 'organisationName'" [value]="data.organisationCode">{{ data.organisationName }}</option>
    </select>
  </div>

  <!-- Chart Download -->
  <div class="download" #dlChart>
    <div class="shield"></div>
    <div class="chart" [ng2-highcharts]="chartOptions"></div>
    <div class="title">
      <img src="/assets/zsa_logo.png" alt="Zero Suicide Alliance logo">
      <h5 *ngIf="chartDetail">{{ chartDetail.title }} ({{ dlDate.dateName }})</h5>
      <p>
        <a href="https://www.zerosuicidealliance.com/">Source: Zero Suicide Alliance</a>
        <ng-container *ngIf="dlHighlighted">
          <br>
          <i class="fas fa-square mr-2"></i>
          <strong>Highlighted:</strong>&nbsp;{{ dlHighlighted.organisationName }} (<span>{{ dlHighlighted.value | number: dataDecimal }}</span><span *ngIf="chartDetail.format == 'P'">%</span>)
        </ng-container>
      </p>
    </div>
  </div>
  <button (click)="download('chart')" class="btn btn-primary">
    <i class="far fa-chart-bar mr-2"></i>Download Chart
  </button>

  <!-- Map Download -->
  <div class="download" style="width: 600px" #dlMap>
    <div id="mapContainer"></div>
    <div class="title">
      <img src="/assets/zsa_logo.png" alt="Zero Suicide Alliance logo">
      <h5 *ngIf="chartDetail">{{ chartDetail.title }} ({{ dlDate.dateName }})</h5>
      <p>
        <a href="https://www.zerosuicidealliance.com/">Source: Zero Suicide Alliance</a>
        <ng-container *ngIf="dlHighlighted">
          <br>
          <i class="far fa-square mr-2"></i>
          <strong>Highlighted:</strong>&nbsp;{{ dlHighlighted.organisationName }} (<span>{{ dlHighlighted.value | number: dataDecimal }}</span><span *ngIf="chartDetail.format == 'P'">%</span>)
        </ng-container>
      </p>
    </div>
  </div>
  <button (click)="download('map')" class="btn btn-primary">
    <i class="far fa-globe-europe mr-2"></i>'Download Map'
  </button>

  <!-- Background canvas and link for downloads-->
  <img #dlCanvas><a #dlLink></a>
</div>

<!-- Glossary popup -->
<div class="glossaryPopup" *ngIf="openGlossary">
  <div class="closer m-2" (click)="openGlossary = !openGlossary">&times;</div>  
  <div class="intro mt-2">
    <p class="mb-0">
      {{selectedTermDescription}}
    </p>
  </div>  
</div>