<div class="container">

    <!-- Title and help bar -->
    <div class="row position-relative mt-4">
      <div class="col-lg-8 col-12">
        <h1 class="font-weight-bold">Privacy Policy</h1>
        <p class="lead text-muted">
          Last updated: <b>25/09/2023</b>
        </p>
      </div>
    </div>
  
    <div class="row justify-content-start">
      <div class="col-lg-8 col-12">
        <h4>We respect the privacy of our users</h4>
        <p>
          This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
        </p>
        <p>
          <b>We reserve the right to make changes to this Privacy Policy at any time and for any reason.</b> We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification.
        </p>
        <p>
          You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.
        </p>
      </div>
    </div>
  
    <div class="row mt-4">
      <div class="col-lg-8 col-12">
        <h4>Collection of your information</h4>
        <p>
          We may collect information about you in a variety of ways. The information we may collect on the Site includes:
        </p>
        <p>
          <b>Derivative Data:</b> Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system and your access times. If you are using a mobile to access the site, the information may also include your device name and type, your operating system, your country and other interactions with the application.
        </p>
      </div>
    </div>
  
    <div class="row justify-content-start mt-4">
      <div class="col-lg-8 col-12">
        <h4>Use of your information</h4>
        <p>
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
        </p>
        <ul>
          <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
          <li>Increase the efficiency and operation of the Site.</li>
          <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
        </ul>
      </div>
    </div>
  
    <div class="row justify-content-start">
      <div class="col-lg-8 col-12">
        <h4>Tracking technologies</h4>      
        <p>
          <b>Cookies and Web Beacons:</b> We use cookies and web beacons a tracking technology on the Site to help customize the Site and improve your experience. When you access the Site, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Site. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by  modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
        </p>
        <p>
          <b>Website Analytics:</b> We may also partner with selected third-party vendors such as Google Analytics to allow tracking technologies and remarketing services on the Site through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site, determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors.
        </p>  
        <p>
          You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
        </p>
        <p>
          <b>Third-party websites:</b> The Site may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy  policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site.
        </p>
    </div>
  </div>