<!-- Input -->
<div class="form-group mt-2" (click)="showOrgs = !showOrgs">
    <label for="search" class="font-weight-bold">Select a local authority or Sub-ICB...</label>
    <input id="search" type="text" [(ngModel)]="searchText" class="form-control hover-pointer bg-white" [placeholder]="selectedOrg ? selectedOrg?.name : 'Search by name or ONS code...'">
</div>
<!-- Drop-down -->
<div class="position:relative">
    <ng-container *ngIf="showOrgs || (searchText && searchText.length)">
        <div class="card" style="position:absolute;top:6.5em;width:80%">
            <div class="card-body">
                <ng-container *ngFor="let group of orgs | groupBy: 'organisationTypeName' | pairs">
                    <div class="font-weight-bold mb-2 hover-pointer" (click)="group.show = !group.show">
                        {{group[0]}}
                        <i class="fas ml-1" [class]="group.show ? 'fa-caret-up' : 'fa-caret-down'"></i>
                    </div>
                    <ng-container *ngIf="group.show || (searchText && searchText.length)">
                        <div *ngFor="let org of group[1] | filterBy: ['name', 'code']: searchText | orderBy: 'name'">
                            <div class="mb-1 pl-1 hover-pointer" (click)="selectOrg(org)">{{org.name}}</div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>